import { CommonModule, formatCurrency, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PRIMARY_OUTLET, Router, RouterLink } from '@angular/router';
import { NgbAlert, NgbAlertModule, NgbModal, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { first, Subscription } from 'rxjs';
import { AppUser } from 'src/app/core/models-two/AppUser';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { VistaServicesService } from 'src/app/core/service/vista-services.service';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { GiftSecondNavbarComponent } from 'src/app/shared/gift-second-navbar/gift-second-navbar.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Volkhov } from 'src/assets/fonts/Volkhov-normal';
import jsPDF from 'jspdf';


@Component({
  selector: 'app-quotation',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetModule,
    NgbAlertModule,
    NavbarComponent,
    FooterComponent,
    RouterLink,
    GiftSecondNavbarComponent,
    NgbProgressbarModule,
    NgbTooltipModule,
  ],
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  show_alert: boolean;
  alert_message: string;
  alert_type: string;
  subscription: Subscription = new Subscription();
  tree: any;
  g: any;
  s: any;
  authState: boolean = false;
  loggedInUserDate: number;
  userId: any;
  loggedInUserFirstName: any;
  loggedInUserSurname: any;
  loggedInUserLastName: any;
  loggedInUserRole: any;
  profilePictureUrl: any;
  userName: string;

  loading: boolean = false;
  dateToday: string;

  quotationClientForm!: FormGroup;
  generalDetailsForm!: FormGroup;
  hospitalForm!: FormGroup;
  accommodationForm!: FormGroup;
  visaForm!: FormGroup;
  vistaForm!: FormGroup;
  totalForm!: FormGroup;
  noteForm!: FormGroup;
  travelForm!: FormGroup;
  updateInProgress: boolean = false;
  submitInProgress: boolean = false;
  previousClientListArray: any[] = [];
  formSubmitted: boolean = false;
  invalidPhoneNumber: boolean = false;
  airlineListArray: string[] = [];
  personCountArray: number[] = [];
  notesItemArray: string[] = [];
  kenyaShillingToUSDExchangeRate: number = 128;
  dayCountArray: number[] = [];
  quotationClientName: any = "Jane Doe";
  quotationTravelItem: any;
  quotationTravelCostUSD: number = 600;
  quotationTravelCostKES: number = (this.quotationTravelCostUSD) * (this.kenyaShillingToUSDExchangeRate);
  quotationTravelItemTicketCount: number = 2;
  quotationTravelItemAirlineName: string = "Air India";
  quotationTreatmentDescription: string = "Health checkup";
  quotationTreatmentCostUSD: number = 1500;
  quotationTreatmentCostKES: number = (this.quotationTreatmentCostUSD) * (this.kenyaShillingToUSDExchangeRate);
  quotationAccommodationDescription: string = "Accommodation at a budget hotel";
  quotationAccommodationPersonCount: number = 2;
  quotationAccommodationCostUSD: number = 560;
  quotationAccommodationCostKES: number = (this.quotationAccommodationCostUSD) * (this.kenyaShillingToUSDExchangeRate);
  quotationVisaDescription: string = "Visa Application Fee";
  quotationVisaApplicantsCount: number = 2;
  quotationVisaCostUSD: number = 166;
  quotationVisaCostKES: number = (this.quotationVisaCostUSD) * (this.kenyaShillingToUSDExchangeRate);
  quotationVistaServicesDescription: string = "Vista Services Fee";
  quotationVistaServicesCostUSD: number = 200;
  quotationVistaServicesCostKES: number = (this.quotationVistaServicesCostUSD) * (this.kenyaShillingToUSDExchangeRate);
  quotationTotalCostUSD: number = 3626;
  quotationTotalCostKES: number = (this.quotationTotalCostUSD) * (this.kenyaShillingToUSDExchangeRate);
  quotationDateCreated: string;
  showForm: boolean;
  quotationId: any;
  idRequested: boolean = false;
  primitiveDate: number;
  error: string;
  quotationAccommodationDayCount: number = 1;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private scroller: ViewportScroller,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private vistaService: VistaServicesService,
    private authenticationService: AuthenticationService,
  ) {
    this.tree = router.parseUrl(router.url);
    this.g = this.tree.root.children[PRIMARY_OUTLET];
    this.s = this.g.segments;

    // this.loadingAnimationFourSeconds();

    this.subscription.add(authenticationService.currentUser.pipe().subscribe((res: AppUser) => {

      if (res) {
        this.authState = true;

        if (res[0]) {
          let firstName = "";
          let otherName = "";
          let surname = "";

          this.loggedInUserDate = (new Date()[Symbol.toPrimitive]('number'));
          if (res[0].userId) { this.userId = res[0].userId; };
          if (res[0].firstName) {
            this.loggedInUserFirstName = res[0].firstName;
            firstName = res[0].firstName;
          };
          if (res[0].surname) {
            this.loggedInUserSurname = res[0].surname;
            surname = res[0].surname;
          };
          if (res[0].otherName) {
            this.loggedInUserLastName = res[0].otherName;
            otherName = res[0].otherName;
          };
          if (res[0].role) { this.loggedInUserRole = res[0].role; };
          if (res[0].profilePicture) {
            if (res[0].profilePicture[0]) {
              if (res[0].profilePicture[0].url) {
                this.profilePictureUrl = res[0].profilePicture[0].url;
              } else {
                this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
              }
            } else {
              this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
            }
          } else {
            this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
          }

          this.userName = `${firstName} ${otherName} ${surname}`;
        } else {
          this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
        }

      } else {
        this.authState = false;
      }

    }));

  };

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  navigateTo(type: string) {

    if (type === "quotationPDF") {
      this.saveQuotation();
      this.printQuotationPDF();
    }

  }

  saveQuotation() {
    this.submitInProgress = true;

    let preparedBy = "";
    let clientFirstName = "";
    let clientOtherName = "";
    let clientSurname = "";
    let clientEmail = "";
    let clientPhoneNumber = 0;
    let quotationId = "";
    let quotationIdFormatted = "";
    let kesToUSDExchangeRate = 0;
    let treatmentDescription = "";
    let treatmentCostUSD = 0;
    let treatmentCostKES = 0;
    let accommodationDescription = "";
    let accommodationPersonCount = 0;
    let accommodationDayCount = 0;
    let accommodationCostPerPersonUSD = 0;
    let accommodationCostPerPersonKES = 0;
    let accommodationCostUSD = 0;
    let accommodationCostKES = 0;
    let visaDescription = "";
    let visaApplicantsCount = 0;
    let visaCostPerVisaUSD = 0;
    let visaCostPerVisaKES = 0;
    let visaCostUSD = 0;
    let visaCostKES = 0;
    let vistaDescription = "";
    let vistaApplicantsCount = 0;
    let vistaCostPerPersonUSD = 0;
    let vistaCostPerPersonKES = 0;
    let vistaCostUSD = 0;
    let vistaCostKES = 0;
    let quotationTotalCostUSD = 0;
    let quotationTotalCostKES = 0;
    let notes = [];
    let travelAirlineName = "";
    let travelAirlineTicketCount = 0;
    let travelAirlineCostPerTicketUSD = 0;
    let travelAirlineCostPerTicketKES = 0;
    let travelAirlineCostUSD = 0;
    let travelAirlineCostKES = 0;
    let date = 0;
    let load: any = {};

    preparedBy = this.userName
    clientFirstName = this.quotationClientForm.get("firstName").value;
    clientOtherName = this.quotationClientForm.get("otherName").value;
    clientSurname = this.quotationClientForm.get("surname").value;
    clientEmail = this.quotationClientForm.get("email").value;
    clientPhoneNumber = this.quotationClientForm.get("phoneNumber").value;
    quotationId = this.generalDetailsForm.get("id").value;
    quotationIdFormatted = this.generalDetailsForm.get("idFormatted").value;
    kesToUSDExchangeRate = this.generalDetailsForm.get("kesToUSDExchangeRate").value;
    treatmentDescription = this.hospitalForm.get("treatmentDescription").value;
    treatmentCostUSD = this.hospitalForm.get("costUSD").value;
    treatmentCostKES = this.hospitalForm.get("costKES").value;
    accommodationDescription = this.accommodationForm.get("accommodationDescription").value;
    accommodationPersonCount = this.accommodationForm.get("personCount").value;
    accommodationDayCount = this.accommodationForm.get("dayCount").value;
    accommodationCostPerPersonUSD = this.accommodationForm.get("costPerPersonUSD").value;
    accommodationCostPerPersonKES = this.accommodationForm.get("costPerPersonKES").value;
    accommodationCostUSD = this.accommodationForm.get("costUSD").value;
    accommodationCostKES = this.accommodationForm.get("costKES").value;
    visaDescription = this.visaForm.get("visaDescription").value;
    visaApplicantsCount = this.visaForm.get("visaApplicantsCount").value;
    visaCostPerVisaUSD = this.visaForm.get("costPerVisaUSD").value;
    visaCostPerVisaKES = this.visaForm.get("costPerVisaKES").value;
    visaCostUSD = this.visaForm.get("costUSD").value;
    visaCostKES = this.visaForm.get("costKES").value;
    vistaDescription = this.vistaForm.get("vistaDescription").value;
    vistaApplicantsCount = this.vistaForm.get("vistaApplicantsCount").value;
    vistaCostPerPersonUSD = this.vistaForm.get("costPerPersonUSD").value;
    vistaCostPerPersonKES = this.vistaForm.get("costPerPersonKES").value;
    vistaCostUSD = this.vistaForm.get("costUSD").value;
    vistaCostKES = this.vistaForm.get("costKES").value;
    quotationTotalCostUSD = this.totalForm.get("totalCostUSD").value;
    quotationTotalCostKES = this.totalForm.get("totalCostKES").value;
    notes = this.notesItemArray;
    travelAirlineName = this.travelForm.get("airlineName").value;
    travelAirlineTicketCount = this.travelForm.get("ticketCount").value;
    travelAirlineCostPerTicketUSD = this.travelForm.get("costPerTicketUSD").value;
    travelAirlineCostPerTicketKES = this.travelForm.get("costPerTicketKES").value;
    travelAirlineCostUSD = this.travelForm.get("costUSD").value;
    travelAirlineCostKES = this.travelForm.get("costKES").value;
    date = new Date()[Symbol.toPrimitive]('number');

    load = {
      preparedBy: preparedBy,
      clientFirstName: clientFirstName,
      clientOtherName: clientOtherName,
      clientSurname: clientSurname,
      clientEmail: clientEmail,
      clientPhoneNumber: clientPhoneNumber,
      quotationId: quotationId,
      quotationIdFormatted: quotationIdFormatted,
      kesToUSDExchangeRate: kesToUSDExchangeRate,
      treatmentDescription: treatmentDescription,
      treatmentCostUSD: treatmentCostUSD,
      treatmentCostKES: treatmentCostKES,
      accommodationDescription: accommodationDescription,
      accommodationPersonCount: accommodationPersonCount,
      accommodationDayCount: accommodationDayCount,
      accommodationCostPerPersonUSD: accommodationCostPerPersonUSD,
      accommodationCostPerPersonKES: accommodationCostPerPersonKES,
      accommodationCostUSD: accommodationCostUSD,
      accommodationCostKES: accommodationCostKES,
      visaDescription: visaDescription,
      visaApplicantsCount: visaApplicantsCount,
      visaCostPerVisaUSD: visaCostPerVisaUSD,
      visaCostPerVisaKES: visaCostPerVisaKES,
      visaCostUSD: visaCostUSD,
      visaCostKES: visaCostKES,
      vistaDescription: vistaDescription,
      vistaApplicantsCount: vistaApplicantsCount,
      vistaCostPerPersonUSD: vistaCostPerPersonUSD,
      vistaCostPerPersonKES: vistaCostPerPersonKES,
      vistaCostUSD: vistaCostUSD,
      vistaCostKES: vistaCostKES,
      quotationTotalCostUSD: quotationTotalCostUSD,
      quotationTotalCostKES: quotationTotalCostKES,
      notes: notes,
      travelAirlineName: travelAirlineName,
      travelAirlineTicketCount: travelAirlineTicketCount,
      travelAirlineCostPerTicketUSD: travelAirlineCostPerTicketUSD,
      travelAirlineCostPerTicketKES: travelAirlineCostPerTicketKES,
      travelAirlineCostUSD: travelAirlineCostUSD,
      travelAirlineCostKES: travelAirlineCostKES,
      date: date
    };

    // Checks
    // if (this.projectForm.controls['id'].invalid) {
    //   this.show_alert = true;
    //   this.alert_message = "Project Id is missing!";
    //   this.alert_type = "danger";

    //   this.submitInProgress = false;
    //   this.toastr.error('Project Id is missing!', 'Error!');

    //   setTimeout(() => {
    //     this.show_alert = false;
    //     this.selfClosingAlert.close();
    //   }, 3000);
    //   return;
    // };

    this.subscription.add(
      this.vistaService.saveQuotation(load)
        .pipe(first()).subscribe((result) => {
          this.formSubmitted = true;
          this.submitInProgress = false;
          this.toastr.success(`Quotation for ${load.clientFirstName} ${load.clientOtherName} ${load.clientSurname} Created`, "Info!");
          this.router.navigate([`/portal/quotation/${load.quotationId}`]);
          // this.projectForm.reset();
        },
          (error: string) => {
            this.error = error;
            this.submitInProgress = false;
          }));

  }

  ngOnInit(): void {

    this.primitiveDate = new Date()[Symbol.toPrimitive]('number');
    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');
    this.dateToday = `${day}-${month}-${year}`;
    this.quotationDateCreated = this.dateToday;

    this.airlineListArray = ["Air India", "Kenya Airways", "Air Asia", "Indigo"];

    this.notesItemArray = [
      "Approximate dollar exchange rate is $1.00 = 128.00 KES.",
      "Hotel stay in a budget hotel includes breakfast & tax.",
      "The Airline Ticket Prices may change depending on time & availability.",
      "This quotation is valid for 4 weeks.",
    ];

    this.personCountArray = [1, 2, 3, 4, 5];

    for (let index = 0; index < 45; index++) {
      this.dayCountArray.push((index + 1));
    }

    this.previousClientListArray = [
      {
        "displayName": "3rd Park",
        "id": "001",
      },
      {
        "displayName": "Aga Khan",
        "id": "002",
      }
    ];

    this.generalDetailsForm = this.fb.group({
      idFormatted: [''],
      id: [''],
      kesToUSDExchangeRate: [128],
    });

    this.subscription.add(
      this.generalDetailsForm.get("id").valueChanges.subscribe(x => {
        this.quotationId = x;
      })
    );

    this.subscription.add(
      this.generalDetailsForm.get("kesToUSDExchangeRate").valueChanges.subscribe(x => {
        this.kenyaShillingToUSDExchangeRate = x;
      })
    );

    this.quotationClientForm = this.fb.group({
      firstName: [''],
      otherName: [''],
      surname: [''],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [''],
    });

    this.subscription.add(
      this.quotationClientForm.get("firstName").valueChanges.subscribe(x => {
        this.quotationClientName = `${this.quotationClientForm.get("firstName").value} ${this.quotationClientForm.get("otherName").value} ${this.quotationClientForm.get("surname").value}`;
        this.getIdOnce();
      })
    );

    this.subscription.add(
      this.quotationClientForm.get("otherName").valueChanges.subscribe(x => {
        this.quotationClientName = `${this.quotationClientForm.get("firstName").value} ${this.quotationClientForm.get("otherName").value} ${this.quotationClientForm.get("surname").value}`;
      })
    );

    this.subscription.add(
      this.quotationClientForm.get("surname").valueChanges.subscribe(x => {
        this.quotationClientName = `${this.quotationClientForm.get("firstName").value} ${this.quotationClientForm.get("otherName").value} ${this.quotationClientForm.get("surname").value}`;
      })
    );

    this.subscription.add(
      this.quotationClientForm.get("phoneNumber").valueChanges.subscribe(x => {
        if (x) {
          //Check if phoneNumber is 9 digits
          if ((Number(x) > 100000000) && (Number(x) < 999999999)) {
            // If so isValid
            this.invalidPhoneNumber = false;
          } else {
            // If not isInvalid
            this.invalidPhoneNumber = true;
          }
        }

      })
    );

    this.travelForm = this.fb.group({
      airlineName: ['Air India'],
      ticketCount: [2],
      costPerTicketUSD: [600],
      costPerTicketKES: [((this.kenyaShillingToUSDExchangeRate) * (600))],
      costUSD: [((600) * (2))],
      costKES: [((this.kenyaShillingToUSDExchangeRate) * ((600) * (2)))],
    });

    this.subscription.add(
      this.travelForm.get("airlineName").valueChanges.subscribe(x => {
        if (x.length > 0) {
          this.quotationTravelItemAirlineName = x;
        } else {
          this.quotationTravelItemAirlineName = "";
        };
      })
    );

    this.subscription.add(
      this.travelForm.get("ticketCount").valueChanges.subscribe(x => {
        if ((x > 0) && ((this.travelForm.get("costPerTicketUSD").value) > 0)) {
          this.travelForm.get("costUSD").setValue(
            (x) * (this.travelForm.get("costPerTicketUSD").value)
          );
          this.travelForm.get("costKES").setValue(
            (x) * (this.travelForm.get("costPerTicketUSD").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationTravelCostKES = ((this.travelForm.get("costUSD").value) * (this.kenyaShillingToUSDExchangeRate));
          this.quotationTravelCostUSD = ((this.travelForm.get("costUSD").value));
          this.quotationTravelItemTicketCount = (this.travelForm.get("ticketCount").value);
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.subscription.add(
      this.travelForm.get("costPerTicketUSD").valueChanges.subscribe(x => {
        if ((x > 0) && ((this.travelForm.get("ticketCount").value) > 0)) {
          this.travelForm.get("costUSD").setValue(
            (x) * (this.travelForm.get("ticketCount").value)
          );
          this.travelForm.get("costKES").setValue(
            (x) * (this.travelForm.get("ticketCount").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationTravelCostKES = ((this.travelForm.get("costUSD").value) * (this.kenyaShillingToUSDExchangeRate));
          this.quotationTravelCostUSD = ((this.travelForm.get("costUSD").value));
          this.quotationTravelItemTicketCount = (this.travelForm.get("ticketCount").value);
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.subscription.add(
      this.generalDetailsForm.get("kesToUSDExchangeRate").valueChanges.subscribe(x => {
        if (((this.travelForm.get("costPerTicketUSD").value) > 0) && ((this.travelForm.get("ticketCount").value) > 0)) {
          this.travelForm.get("costUSD").setValue(
            ((this.travelForm.get("costPerTicketUSD").value)) * (this.travelForm.get("ticketCount").value)
          );
          this.travelForm.get("costKES").setValue(
            ((this.travelForm.get("costPerTicketUSD").value)) * (this.travelForm.get("ticketCount").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationTravelCostKES = ((this.travelForm.get("costUSD").value) * (this.kenyaShillingToUSDExchangeRate));
          this.quotationTravelCostUSD = ((this.travelForm.get("costUSD").value));
          this.quotationTravelItemTicketCount = (this.travelForm.get("ticketCount").value);
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.hospitalForm = this.fb.group({
      treatmentDescription: ['Health checkup'],
      costUSD: [1500],
      costKES: [((this.kenyaShillingToUSDExchangeRate) * 1500)],
    });

    this.subscription.add(
      this.hospitalForm.get("treatmentDescription").valueChanges.subscribe(x => {
        if (x.length > 0) {
          this.quotationTreatmentDescription = x;
        } else {
          this.quotationTreatmentDescription = "";
        };
      })
    );

    this.subscription.add(
      this.hospitalForm.get("costUSD").valueChanges.subscribe(x => {
        if ((x > 0)) {
          this.hospitalForm.get("costKES").setValue(
            (x) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationTreatmentCostKES = ((x) * (this.kenyaShillingToUSDExchangeRate));
          this.quotationTreatmentCostUSD = (x);
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.subscription.add(
      this.generalDetailsForm.get("kesToUSDExchangeRate").valueChanges.subscribe(x => {
        if (((this.hospitalForm.get("costUSD").value) > 0)) {
          this.hospitalForm.get("costKES").setValue(
            ((this.hospitalForm.get("costUSD").value)) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationTreatmentCostKES = (((this.hospitalForm.get("costUSD").value)) * (this.kenyaShillingToUSDExchangeRate));
          this.quotationTreatmentCostUSD = ((this.hospitalForm.get("costUSD").value));
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.accommodationForm = this.fb.group({
      accommodationDescription: ['Accommodation at a budget hotel'],
      personCount: [2],
      dayCount: [14],
      costPerPersonUSD: [20],
      costPerPersonKES: [((this.kenyaShillingToUSDExchangeRate) * 20)],
      costUSD: [(20 * 2 * 14)],
      costKES: [((this.kenyaShillingToUSDExchangeRate) * (20 * 2 * 14))],
    });

    this.subscription.add(
      this.accommodationForm.get("accommodationDescription").valueChanges.subscribe(x => {
        if (x.length > 0) {
          this.quotationAccommodationDescription = x;
        } else {
          this.quotationAccommodationDescription = "";
        };
      })
    );

    this.subscription.add(
      this.accommodationForm.get("personCount").valueChanges.subscribe(x => {
        if (((this.accommodationForm.get("personCount").value) > 0) && ((this.accommodationForm.get("costPerPersonUSD").value) > 0) && ((this.accommodationForm.get("dayCount").value) > 0)) {
          this.accommodationForm.get("costUSD").setValue(
            (this.accommodationForm.get("personCount").value) * (this.accommodationForm.get("costPerPersonUSD").value) * (this.accommodationForm.get("dayCount").value)
          );
          this.accommodationForm.get("costKES").setValue(
            (this.accommodationForm.get("costUSD").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationAccommodationDayCount = (this.accommodationForm.get("dayCount").value);
          this.quotationAccommodationPersonCount = (this.accommodationForm.get("personCount").value);
          this.quotationAccommodationCostKES = ((this.accommodationForm.get("costKES").value));
          this.quotationAccommodationCostUSD = ((this.accommodationForm.get("costUSD").value));
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.subscription.add(
      this.accommodationForm.get("costPerPersonUSD").valueChanges.subscribe(x => {
        if (((this.accommodationForm.get("personCount").value) > 0) && ((this.accommodationForm.get("costPerPersonUSD").value) > 0) && ((this.accommodationForm.get("dayCount").value) > 0)) {
          this.accommodationForm.get("costUSD").setValue(
            (this.accommodationForm.get("personCount").value) * (this.accommodationForm.get("costPerPersonUSD").value) * (this.accommodationForm.get("dayCount").value)
          );
          this.accommodationForm.get("costKES").setValue(
            (this.accommodationForm.get("costUSD").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationAccommodationDayCount = (this.accommodationForm.get("dayCount").value);
          this.quotationAccommodationPersonCount = (this.accommodationForm.get("personCount").value);
          this.quotationAccommodationCostKES = ((this.accommodationForm.get("costKES").value));
          this.quotationAccommodationCostUSD = ((this.accommodationForm.get("costUSD").value));
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.subscription.add(
      this.accommodationForm.get("dayCount").valueChanges.subscribe(x => {
        if (((this.accommodationForm.get("personCount").value) > 0) && ((this.accommodationForm.get("costPerPersonUSD").value) > 0) && ((this.accommodationForm.get("dayCount").value) > 0)) {
          this.accommodationForm.get("costUSD").setValue(
            (this.accommodationForm.get("personCount").value) * (this.accommodationForm.get("costPerPersonUSD").value) * (this.accommodationForm.get("dayCount").value)
          );
          this.accommodationForm.get("costKES").setValue(
            (this.accommodationForm.get("costUSD").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationAccommodationDayCount = (this.accommodationForm.get("dayCount").value);
          this.quotationAccommodationPersonCount = (this.accommodationForm.get("personCount").value);
          this.quotationAccommodationCostKES = ((this.accommodationForm.get("costKES").value));
          this.quotationAccommodationCostUSD = ((this.accommodationForm.get("costUSD").value));
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.subscription.add(
      this.generalDetailsForm.get("kesToUSDExchangeRate").valueChanges.subscribe(x => {
        if (((this.accommodationForm.get("personCount").value) > 0) && ((this.accommodationForm.get("costPerPersonUSD").value) > 0) && ((this.accommodationForm.get("dayCount").value) > 0)) {
          this.accommodationForm.get("costUSD").setValue(
            (this.accommodationForm.get("personCount").value) * (this.accommodationForm.get("costPerPersonUSD").value) * (this.accommodationForm.get("dayCount").value)
          );
          this.accommodationForm.get("costKES").setValue(
            (this.accommodationForm.get("costUSD").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationAccommodationDayCount = (this.accommodationForm.get("dayCount").value);
          this.quotationAccommodationPersonCount = (this.accommodationForm.get("personCount").value);
          this.quotationAccommodationCostKES = ((this.accommodationForm.get("costKES").value));
          this.quotationAccommodationCostUSD = ((this.accommodationForm.get("costUSD").value));
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.visaForm = this.fb.group({
      visaDescription: ['Visa Application Fee'],
      visaApplicantsCount: [2],
      costPerVisaUSD: [83],
      costPerVisaKES: [((this.kenyaShillingToUSDExchangeRate) * 83)],
      costUSD: [(83 * 2)],
      costKES: [((this.kenyaShillingToUSDExchangeRate) * (83 * 2))],
    });

    this.subscription.add(
      this.visaForm.get("visaDescription").valueChanges.subscribe(x => {
        if (x.length > 0) {
          this.quotationVisaDescription = x;
        } else {
          this.quotationVisaDescription = "";
        };
      })
    );

    this.subscription.add(
      this.visaForm.get("visaApplicantsCount").valueChanges.subscribe(x => {
        if ((x > 0) && ((this.visaForm.get("costPerVisaUSD").value) > 0)) {
          this.visaForm.get("costUSD").setValue(
            (x) * (this.visaForm.get("costPerVisaUSD").value)
          );
          this.visaForm.get("costKES").setValue(
            (x) * (this.visaForm.get("costPerVisaUSD").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationVisaCostKES = ((x) * (this.visaForm.get("costPerVisaUSD").value) * (this.kenyaShillingToUSDExchangeRate));
          this.quotationVisaCostUSD = ((x) * (this.visaForm.get("costPerVisaUSD").value));
          this.quotationVisaApplicantsCount = x;
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.subscription.add(
      this.visaForm.get("costPerVisaUSD").valueChanges.subscribe(x => {
        if ((x > 0) && ((this.visaForm.get("visaApplicantsCount").value) > 0)) {
          this.visaForm.get("costUSD").setValue(
            (x) * (this.visaForm.get("visaApplicantsCount").value)
          );
          this.visaForm.get("costKES").setValue(
            (x) * (this.visaForm.get("visaApplicantsCount").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationVisaCostKES = ((x) * (this.visaForm.get("visaApplicantsCount").value) * (this.kenyaShillingToUSDExchangeRate));
          this.quotationVisaCostUSD = ((x) * (this.visaForm.get("visaApplicantsCount").value));
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.subscription.add(
      this.generalDetailsForm.get("kesToUSDExchangeRate").valueChanges.subscribe(x => {
        if (((this.visaForm.get("costPerVisaUSD").value) > 0) && ((this.visaForm.get("visaApplicantsCount").value) > 0)) {
          this.visaForm.get("costUSD").setValue(
            ((this.visaForm.get("costPerVisaUSD").value)) * (this.visaForm.get("visaApplicantsCount").value)
          );
          this.visaForm.get("costKES").setValue(
            ((this.visaForm.get("costPerVisaUSD").value)) * (this.visaForm.get("visaApplicantsCount").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationVisaCostKES = (((this.visaForm.get("costPerVisaUSD").value)) * (this.visaForm.get("visaApplicantsCount").value) * (this.kenyaShillingToUSDExchangeRate));
          this.quotationVisaCostUSD = (((this.visaForm.get("costPerVisaUSD").value)) * (this.visaForm.get("visaApplicantsCount").value));
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.vistaForm = this.fb.group({
      vistaDescription: ['Vista Services Fee'],
      vistaApplicantsCount: [2],
      costPerPersonUSD: [100],
      costPerPersonKES: [((this.kenyaShillingToUSDExchangeRate) * 100)],
      costUSD: [(100 * 2)],
      costKES: [((this.kenyaShillingToUSDExchangeRate) * (100 * 2))],
    });

    this.subscription.add(
      this.vistaForm.get("vistaDescription").valueChanges.subscribe(x => {
        if (x.length > 0) {
          this.quotationVistaServicesDescription = x;
        } else {
          this.quotationVistaServicesDescription = "";
        };
      })
    );

    this.subscription.add(
      this.vistaForm.get("vistaApplicantsCount").valueChanges.subscribe(x => {
        if ((x > 0) && ((this.vistaForm.get("costPerPersonUSD").value) > 0)) {
          this.vistaForm.get("costUSD").setValue(
            (x) * (this.vistaForm.get("costPerPersonUSD").value)
          );
          this.vistaForm.get("costKES").setValue(
            (x) * (this.vistaForm.get("costPerPersonUSD").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationVisaApplicantsCount = (x);
          this.quotationVistaServicesCostUSD = (((this.vistaForm.get("costUSD").value)));
          this.quotationVistaServicesCostKES = (((this.vistaForm.get("costUSD").value) * (this.kenyaShillingToUSDExchangeRate)));
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.subscription.add(
      this.vistaForm.get("costPerPersonUSD").valueChanges.subscribe(x => {
        if ((x > 0) && ((this.vistaForm.get("vistaApplicantsCount").value) > 0)) {
          this.vistaForm.get("costUSD").setValue(
            (x) * (this.vistaForm.get("vistaApplicantsCount").value)
          );
          this.vistaForm.get("costKES").setValue(
            (x) * (this.vistaForm.get("vistaApplicantsCount").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationVistaServicesCostUSD = (((this.vistaForm.get("costUSD").value)));
          this.quotationVistaServicesCostKES = (((this.vistaForm.get("costUSD").value) * (this.kenyaShillingToUSDExchangeRate)));
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.subscription.add(
      this.generalDetailsForm.get("kesToUSDExchangeRate").valueChanges.subscribe(x => {
        if (((this.vistaForm.get("costPerPersonUSD").value) > 0) && ((this.vistaForm.get("vistaApplicantsCount").value) > 0)) {
          this.vistaForm.get("costUSD").setValue(
            ((this.vistaForm.get("costPerPersonUSD").value)) * (this.vistaForm.get("vistaApplicantsCount").value)
          );
          this.vistaForm.get("costKES").setValue(
            ((this.vistaForm.get("costPerPersonUSD").value)) * (this.vistaForm.get("vistaApplicantsCount").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationVistaServicesCostUSD = (((this.vistaForm.get("costUSD").value)));
          this.quotationVistaServicesCostKES = (((this.vistaForm.get("costUSD").value) * (this.kenyaShillingToUSDExchangeRate)));
          this.calculateTotalKES();
          this.calculateTotalUSD();
        };
      })
    );

    this.totalForm = this.fb.group({
      totalCostUSD: [3626],
      totalCostKES: [((this.kenyaShillingToUSDExchangeRate) * 3626)],
    });

    // this.subscription.add(
    //   this.totalForm.get("totalCostUSD").valueChanges.subscribe(x => {
    //     if ((x > 0)) {
    //       this.totalForm.get("totalCostKES").setValue(
    //         (x) * (this.kenyaShillingToUSDExchangeRate)
    //       );
    //       this.quotationTotalCostKES = ((x) * (this.kenyaShillingToUSDExchangeRate));
    //     } else {
    //       this.quotationTotalCostKES = (0);
    //     };
    //   })
    // );

    this.subscription.add(
      this.generalDetailsForm.get("kesToUSDExchangeRate").valueChanges.subscribe(x => {
        if (((this.totalForm.get("totalCostUSD").value) > 0)) {
          this.totalForm.get("totalCostKES").setValue(
            (this.totalForm.get("totalCostUSD").value) * (this.kenyaShillingToUSDExchangeRate)
          );
          this.quotationTotalCostKES = ((this.totalForm.get("totalCostUSD").value) * (this.kenyaShillingToUSDExchangeRate));
        } else {
          this.quotationTotalCostKES = (0);
        };

        // Edit Notes
        this.notesItemArray.filter(a => {
          let indexAB = 0;
          this.notesItemArray.forEach(element => {
            if (element.toLowerCase().includes("exchange")) {
              console.log("indexAB: ", indexAB);

              this.notesItemArray.splice(indexAB, 1);
              this.notesItemArray.push(`Approximate dollar exchange rate is $1.00 = ${this.kenyaShillingToUSDExchangeRate} KES.`)

            } else {
              // Ignore
            }
            indexAB += 1;
          });
        });

      })
    );

    this.noteForm = this.fb.group({
      number: [''],
      description: [''],
    });

  };

  getIdOnce() {
    console.log("getIdOnce");

    if (!(this.idRequested)) {
      console.log("!(this.idRequested)");
      this.generateId();
      this.idRequested = true;
    } else {

    }
  }

  calculateTotalUSD() {

    this.quotationTotalCostUSD = 0;

    this.totalForm.get("totalCostUSD").setValue(
      (this.travelForm.get("costUSD").value) +
      (this.hospitalForm.get("costUSD").value) +
      (this.accommodationForm.get("costUSD").value) +
      (this.visaForm.get("costUSD").value) +
      (this.vistaForm.get("costUSD").value)
    );
    this.quotationTotalCostUSD = (
      (this.travelForm.get("costUSD").value) +
      (this.hospitalForm.get("costUSD").value) +
      (this.accommodationForm.get("costUSD").value) +
      (this.visaForm.get("costUSD").value) +
      (this.vistaForm.get("costUSD").value)
    );

  };

  calculateTotalKES() {

    this.quotationTotalCostKES = 0;

    this.totalForm.get("totalCostKES").setValue(
      (this.travelForm.get("costKES").value) +
      (this.hospitalForm.get("costKES").value) +
      (this.accommodationForm.get("costKES").value) +
      (this.visaForm.get("costKES").value) +
      (this.vistaForm.get("costKES").value)
    );
    this.quotationTotalCostKES = (
      (this.travelForm.get("costKES").value) +
      (this.hospitalForm.get("costKES").value) +
      (this.accommodationForm.get("costKES").value) +
      (this.visaForm.get("costKES").value) +
      (this.vistaForm.get("costKES").value)
    );

  };


  ngAfterViewInit(): void {


  };

  printQuotationPDF() {

    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');

    //
    this.dateToday = `${day}-${month}-${year}`;

    this.notify("Downloading Quotation ...");

    const param = {
      outputType: "save",
      returnJsPDFDocObject: false,
      fileName: `${this.quotationClientName} - Quotation - ${this.dateToday}.pdf`,
      orientationLandscape: false,
      compress: true,
      pageEnable: true,
      pageLabel: "Page",
    };

    var doc = new jsPDF(param.orientationLandscape ? "landscape" : "p", "mm", "a4", param.compress);

    // add the font to jsPDF
    doc.addFileToVFS("Volkhov.ttf", Volkhov);
    doc.addFont('Volkhov.ttf', 'Volkhov', 'normal');

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    var colorYellow = "#CDAE59";
    var colorBlue = "#353E59";
    var colorRed = "#fa5c7c";
    var colorGreen = "#0acf97";
    var colorWhite = "#ffffff";
    //starting at 15mm
    var currentHeight = 15;
    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
      headerTextSize: 16,
      labelTextSize: 12,
      fieldTextSize: 11,
      lineHeight: 9,
      subLineHeight: 5,
      headerLineHeight: 6,
      headerSubLineHeight: 4,
      headerFieldTextSize: 10,
    };

    var addHeader = function () {
      currentHeight = 15;

      // Background Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_43_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        0,
        -1,
        210,
        30
      );

      // Logo Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/logo_315_x_315.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        docWidth - 60,
        currentHeight - 3,
        35,
        35
      );

      // Logo Image 2
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_logo_350_x_350_center_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        45,
        90,
        120,
        120
      );

      doc.setFontSize(20);
      doc.setTextColor(colorWhite);
      doc.text(`Vista Services, Kenya`, 45, 15, { align: 'center' },);
      doc.setTextColor(colorBlack);

      //line breaker after logo & business info
      // doc.line(10, 45, docWidth - 10, 45);
      currentHeight = 45;
    }

    addHeader();

    // Start
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.setFontSize(12);

    currentHeight += pdfConfig.lineHeight;

    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(25, currentHeight - 7, docWidth - 50, 10, 0, 0);
    doc.setDrawColor(0);

    doc.setFontSize(16);
    doc.text(`Approximate Quotation`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(12);
    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(25, currentHeight - 2, docWidth - 50, 10, 0, 0);
    doc.setDrawColor(0);

    currentHeight += pdfConfig.subLineHeight;
    doc.setTextColor(colorGray);
    doc.text(`Patient Name `, docWidth / 2, currentHeight, { align: 'right' },);
    doc.text(`:`, docWidth / 2, currentHeight, { align: 'center' },);

    if (this.quotationClientName) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.quotationClientName}`, docWidth / 2, currentHeight, { align: 'left' },);
    };

    doc.setFontSize(12);
    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(25, currentHeight + 3, docWidth - 50, 80, 0, 0);
    doc.roundedRect(25, currentHeight + 3, docWidth - 50, 12, 0, 0);
    doc.roundedRect(25, currentHeight + 3, 90, 12, 0, 0);
    doc.roundedRect(115, currentHeight + 3, 70, 6, 0, 0);

    currentHeight += pdfConfig.lineHeight;

    doc.roundedRect(150, currentHeight, 35, 6, 0, 0);
    doc.setDrawColor(0);

    doc.setTextColor(colorGray);
    doc.text(`Package Details`, docWidth / 3, (currentHeight + 1), { align: 'center' },);
    doc.text(`Cost`, (docWidth - 59), (currentHeight - 1), { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.text(`USD`, (docWidth - 77), currentHeight, { align: 'center' },);
    doc.text(`KES`, (docWidth - 40), currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.text(`Travel`, 27, currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    if ((this.quotationTravelItemAirlineName) || (this.quotationTravelItemTicketCount)) { doc.text(`1. ${this.quotationTravelItemAirlineName} for ${this.quotationTravelItemTicketCount} persons.`, 29, currentHeight, { align: 'left' },); }
    if (this.quotationTravelCostUSD) { doc.text(`${formatCurrency((this.quotationTravelCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); }
    if (this.quotationTravelCostKES) { doc.text(`${formatCurrency((this.quotationTravelCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },); };
    currentHeight += pdfConfig.subLineHeight;

    doc.setTextColor(colorGray);
    doc.text(`Treatment`, 27, currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    if (this.quotationTreatmentDescription) { doc.text(`2. ${this.quotationTreatmentDescription}.`, 29, currentHeight, { align: 'left' },); }
    if (this.quotationTreatmentCostUSD) { doc.text(`${formatCurrency((this.quotationTreatmentCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); }
    if (this.quotationTreatmentCostKES) { doc.text(`${formatCurrency((this.quotationTreatmentCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },); };
    currentHeight += pdfConfig.subLineHeight;

    doc.setTextColor(colorGray);
    doc.text(`Accommodation`, 27, currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    if (this.quotationAccommodationDescription) { doc.text(`3. ${this.quotationAccommodationDescription}`, 29, currentHeight, { align: 'left' },); };
    if (this.quotationAccommodationCostUSD) { doc.text(`${formatCurrency((this.quotationAccommodationCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); }
    if (this.quotationAccommodationCostKES) { doc.text(`${formatCurrency((this.quotationAccommodationCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },); };
    currentHeight += pdfConfig.subLineHeight;
    if (this.quotationAccommodationPersonCount) { doc.text(`${this.quotationAccommodationDayCount} days for ${this.quotationAccommodationPersonCount} persons.`, 34, currentHeight, { align: 'left' },); };
    currentHeight += pdfConfig.subLineHeight;

    doc.setTextColor(colorGray);
    doc.text(`Visa`, 27, currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    if ((this.quotationVisaDescription) || (this.quotationVisaApplicantsCount)) { doc.text(`4. ${this.quotationVisaDescription} for ${this.quotationVisaApplicantsCount} persons.`, 29, currentHeight, { align: 'left' },); }
    if (this.quotationVisaCostUSD) { doc.text(`${formatCurrency((this.quotationVisaCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); }
    if (this.quotationVisaCostKES) { doc.text(`${formatCurrency((this.quotationVisaCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },); };
    currentHeight += pdfConfig.subLineHeight;

    doc.setTextColor(colorGray);
    doc.text(`Vista Services`, 27, currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    if ((this.quotationVistaServicesDescription)) { doc.text(`5. ${this.quotationVistaServicesDescription}.`, 29, currentHeight, { align: 'left' },); }
    if (this.quotationVistaServicesCostUSD) { doc.text(`${formatCurrency((this.quotationVistaServicesCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); }
    if (this.quotationVistaServicesCostKES) { doc.text(`${formatCurrency((this.quotationVistaServicesCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },); };
    currentHeight += pdfConfig.lineHeight;

    doc.text(`Total`, 90, currentHeight, { align: 'left' },);
    if (this.quotationTotalCostUSD) { doc.text(`${formatCurrency((this.quotationTotalCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); };
    if (this.quotationTotalCostKES) {
      doc.setFontSize(12);
      doc.text(`${formatCurrency((this.quotationTotalCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },);
      doc.setFontSize(12);
    };
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(12);
    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(25, currentHeight + 3, docWidth - 50, 35, 1, 1);
    doc.setDrawColor(0);

    currentHeight += pdfConfig.lineHeight;
    doc.text(`Notes`, 27, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.subLineHeight;

    if (this.notesItemArray) {
      if (this.notesItemArray.length > 0) {
        let xy = this.notesItemArray;
        let indexAB = 1;
        xy.forEach(element => {
          doc.text(`${indexAB}. ${element}`, 29, currentHeight, { align: 'left' },);
          currentHeight += pdfConfig.subLineHeight;
          indexAB += 1;
        });
      } else {
        currentHeight += pdfConfig.subLineHeight;
      };
    } else {
      currentHeight += pdfConfig.subLineHeight;
    };

    // Stamp, Date & Signature.
    currentHeight += pdfConfig.lineHeight;

    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
    doc.setDrawColor(0);

    currentHeight += pdfConfig.lineHeight;
    doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
    doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.lineHeight;
    doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

    if (this.quotationDateCreated) {
      doc.text(` ${this.quotationDateCreated}`, 120, currentHeight, { align: 'left' },);
    } else {
      doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
    }

    // stamp_750_x287
    var imageHeader = new Image();
    imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
    doc.addImage(
      imageHeader,
      "JPEG",
      120,
      currentHeight - 25,
      75,
      28.7
    );




    //#region Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(pdfConfig.headerFieldTextSize);
        doc.setTextColor(colorWhite);

        if (param.pageEnable) {
          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            0,
            doc.internal.pageSize.height - 29,
            210,
            30
          );

          doc.text("Park Medical Center (PMC), 3rd Parklands Avenue, 2nd Floor, Nairobi.", 15, docHeight - 12, { align: 'left' });
          doc.text("+254 718 862 006", 160, docHeight - 12, { align: 'left' });
          doc.text("www.vistaservices.co.ke", 15, docHeight - 3, { align: 'left' });
          doc.text("www.facebook.com/VistaServicesKenya", 116, docHeight - 3, { align: 'left' });
          doc.setTextColor(colorBlack);
          doc.text(
            param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
            docWidth - 30,
            doc.internal.pageSize.height - 35,
            { align: 'left' }
          );
          doc.setTextColor(colorWhite);

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_globe_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            8,
            doc.internal.pageSize.height - 7,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_location_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            8,
            doc.internal.pageSize.height - 16,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_phone_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            154,
            doc.internal.pageSize.height - 16,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_facebook_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            110,
            doc.internal.pageSize.height - 7,
            5,
            5
          );
        }

      }
    }

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        0,
        doc.internal.pageSize.height - 29,
        210,
        30
      );

      doc.setFontSize(pdfConfig.fieldTextSize);
      doc.setTextColor(colorWhite);
      doc.text("Park Medical Center (PMC), 3rd Parklands Avenue, 2nd Floor, Nairobi.", 15, docHeight - 12, { align: 'left' });
      doc.text("+254 718 862 006", 160, docHeight - 12, { align: 'left' });
      doc.text("www.vistaservices.co.ke", 15, docHeight - 3, { align: 'left' });
      doc.text("www.facebook.com/VistaServicesKenya", 116, docHeight - 3, { align: 'left' });
      doc.setTextColor(colorBlack);
      doc.text(
        param.pageLabel + " 1 / 1",
        docWidth - 30,
        doc.internal.pageSize.height - 35,
        { align: 'left' }
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_globe_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        8,
        doc.internal.pageSize.height - 7,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_location_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        8,
        doc.internal.pageSize.height - 16,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_phone_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        154,
        doc.internal.pageSize.height - 16,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_facebook_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        110,
        doc.internal.pageSize.height - 7,
        5,
        5
      );

      doc.setTextColor(colorWhite);
    }
    //#endregion


    let returnObj = {
      pagesNumber: doc.getNumberOfPages(),
    };



    if (param.outputType === "save") {
      // this.disableDownloadButton = false;
      doc.save(param.fileName);
    }

    return returnObj;
  };

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  /**
   * convenience getter for easy access to form fields
   */

  get travelFormValues() {
    return this.travelForm.controls;
  }

  get generalDetailsFormValues() {
    return this.generalDetailsForm.controls;
  }

  get quotationClientFormValues() {
    return this.quotationClientForm.controls;
  }

  get hospitalFormValues() {
    return this.hospitalForm.controls;
  }

  get visaFormValues() {
    return this.visaForm.controls;
  }

  get vistaFormValues() {
    return this.vistaForm.controls;
  }

  get noteFormValues() {
    return this.noteForm.controls;
  }

  get accommodationFormValues() {
    return this.accommodationForm.controls;
  }

  generateId() {

    console.log("generateId: ");

    // Get new Id
    this.subscription.add(
      this.vistaService.getQuotationId().pipe(first()).subscribe((result) => {
        if (result) {
          this.generalDetailsForm.get("id").setValue(result.replaceAll("/", ""));
          this.generalDetailsForm.get("idFormatted").setValue(result);
          // this.quotationId = result;
        }
      })
    );
  }

  editForm(id: string) {

    // Show new tool form
    this.showForm = true;

    // Clear form

    //Populate Form
    // if (this.projectObject) {
    //   if (this.projectObject[id]) {
    //     if (this.projectObject[id][0]) {
    //       if (this.projectObject[id][0].id) { this.projectForm.get("id").setValue(this.projectObject[id][0].id); };
    //       if (this.projectObject[id][0].index) { this.projectForm.get("index").setValue(this.projectObject[id][0].index); };
    //       if (this.projectObject[id][0].name) { this.projectForm.get("name").setValue(this.projectObject[id][0].name); };

    //       if (this.projectObject[id][0].currencyName) { this.projectForm.get("currencyName").setValue(this.projectObject[id][0].currencyName); };
    //       if (this.projectObject[id][0].currencyMagnitudeDealer) { this.projectForm.get("currencyMagnitudeDealer").setValue(this.projectObject[id][0].currencyMagnitudeDealer); };
    //       if (this.projectObject[id][0].currencyMagnitudeCompany) { this.projectForm.get("currencyMagnitudeCompany").setValue(this.projectObject[id][0].currencyMagnitudeCompany); };
    //       if (this.projectObject[id][0].unitsOfMeasure) { this.projectForm.get("unitsOfMeasure").setValue(this.projectObject[id][0].unitsOfMeasure); };
    //       if (this.projectObject[id][0].localCurrencyName) { this.projectForm.get("localCurrencyName").setValue(this.projectObject[id][0].localCurrencyName); };
    //       if (this.projectObject[id][0].localCurrencyISOCode) { this.projectForm.get("localCurrencyISOCode").setValue(this.projectObject[id][0].localCurrencyISOCode); };
    //       if (this.projectObject[id][0].corporateTaxRate) { this.projectForm.get("corporateTaxRate").setValue(this.projectObject[id][0].corporateTaxRate); };
    //       if (this.projectObject[id][0].taxCarryOverPeriod) { this.projectForm.get("taxCarryOverPeriod").setValue(this.projectObject[id][0].taxCarryOverPeriod); };
    //       if (this.projectObject[id][0].discountRate) { this.projectForm.get("discountRate").setValue(this.projectObject[id][0].discountRate); };
    //       if (this.projectObject[id][0].fxRate) { this.projectForm.get("fxRate").setValue(this.projectObject[id][0].fxRate); };
    //       if (this.projectObject[id][0].taxFuelTurnover) { this.projectForm.get("taxFuelTurnover").setValue(this.projectObject[id][0].taxFuelTurnover); };
    //       if (this.projectObject[id][0].product) { this.projectForm.get("product").setValue(this.projectObject[id][0].product); };
    //       if (this.projectObject[id][0].inflation) { this.projectForm.get("inflation").setValue(this.projectObject[id][0].inflation); };
    //       if (this.projectObject[id][0].created) { this.projectForm.get("created").setValue(this.projectObject[id][0].created); };

    //     }
    //   }
    // }
  }

  cancelUpdate() {
    // Show new tool form
    this.showForm = false;

    // Clear form

  };

  removeNoteItem(num: number) {
    this.notesItemArray.splice(num, 1);
  };

  addNoteItem(form: any) {
    this.notesItemArray.push(form.description);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  };

}
