import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class VistaServicesService {

  constructor(
    private db: AngularFireDatabase,
    private fns: AngularFireFunctions,
    private storage: AngularFireStorage,
  ) { }

  getBookingArray() {
    return this.db.list('/booking').valueChanges();
  }

  getBookingObject() {
    return this.db.object('/booking').valueChanges();
  }

  getInvoiceArray() {
    return this.db.list('/invoice').valueChanges();
  }

  getInvoiceObject() {
    return this.db.object('/invoice').valueChanges();
  }

  getReceiptArray() {
    return this.db.list('/receipt').valueChanges();
  }

  getReceiptObject() {
    return this.db.object('/receipt').valueChanges();
  }

  getQuotationArray() {
    return this.db.list('/quotation').valueChanges();
  }

  getQuotationObject() {
    return this.db.object('/quotation').valueChanges();
  }

  getInvoiceId() {
    const callable = this.fns.httpsCallable('generateInvoiceId');
    return callable({ type: '' });
  }

  getReceiptId() {
    const callable = this.fns.httpsCallable('generateReceiptId');
    return callable({ type: '' });
  }

  getQuotationId() {
    const callable = this.fns.httpsCallable('generateQuotationId');
    return callable({ type: '' });
  }

  saveReceipt(load: any) {
    const callable = this.fns.httpsCallable('saveReceipt');
    return callable({
      a: load,
    });
  }

  saveInvoice(load: any) {
    const callable = this.fns.httpsCallable('saveInvoice');
    return callable({
      a: load,
    });
  }

  saveBooking(load: any) {
    const callable = this.fns.httpsCallable('saveBooking');
    return callable({
      a: load,
    });
  }

  saveQuotation(load: any) {
    const callable = this.fns.httpsCallable('saveQuotation');
    return callable({
      a: load,
    });
  }

  deleteItem(type:string,id: string) {
    const callable = this.fns.httpsCallable('deleteItem');
    return callable({
      id: id,
      a: type,
    });
  }

}
