<div class="card bg-transparent">
    <!-- <app-ui-preloader [display]="loading"></app-ui-preloader> -->
    <!-- Header -->
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Invoice</h1>
                    <p class="lead text-muted">Fill in the invoice detail.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">1. General Details :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="generalDetailsForm">

                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="date" class="form-label">Invoice Date<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="date" id="date" placeholder="Enter the invoice date..."
                                required formControlName="date"
                                [ngClass]="{'is-invalid': (formSubmitted && generalDetailsFormValues['date'].invalid)||(generalDetailsFormValues['date'].touched && generalDetailsFormValues['date'].invalid)}">
                            <div *ngIf="(formSubmitted && generalDetailsFormValues['date'].invalid)||(generalDetailsFormValues['date'].touched && generalDetailsFormValues['date'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="generalDetailsFormValues['date'].errors?.['required']">
                                    Invoice Date is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="id" class="form-label">Invoice Id.<span class="text-danger"> *</span></label>
                            <input class="form-control" type="text" id="id" required readonly formControlName="id"
                                [ngClass]="{'is-invalid': (formSubmitted && generalDetailsFormValues['id'].invalid)||(generalDetailsFormValues['id'].touched && generalDetailsFormValues['id'].invalid)}">
                            <div *ngIf="(formSubmitted && generalDetailsFormValues['id'].invalid)||(generalDetailsFormValues['id'].touched && generalDetailsFormValues['id'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="generalDetailsFormValues['id'].errors?.['required']">
                                    Invoice Id is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>

        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">2. Client :</h4>
            </div>
            <div class="card-body">
                <!-- form -->
                <form name="signup-form" [formGroup]="invoiceClientForm">

                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label for="companyName" class="form-label">Company Name<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="text" id="companyName" minLength="3" maxLength="30"
                                placeholder="Enter the company name." required formControlName="companyName"
                                [ngClass]="{'is-invalid': (formSubmitted && invoiceClientFormValues['companyName'].invalid)||(invoiceClientFormValues['companyName'].touched && invoiceClientFormValues['companyName'].invalid)}">
                            <div *ngIf="(formSubmitted && invoiceClientFormValues['companyName'].invalid)||(invoiceClientFormValues['companyName'].touched && invoiceClientFormValues['companyName'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="invoiceClientFormValues['companyName'].errors?.['required']">
                                    Company Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label for="address" class="form-label">Address<span class="text-danger">
                                    *</span></label>
                            <input class="form-control" type="text" id="address" minLength="3" maxLength="30"
                                placeholder="Enter the company address." required formControlName="address"
                                [ngClass]="{'is-invalid': (formSubmitted && invoiceClientFormValues['address'].invalid)||(invoiceClientFormValues['address'].touched && invoiceClientFormValues['address'].invalid)}">
                            <div *ngIf="(formSubmitted && invoiceClientFormValues['address'].invalid)||(invoiceClientFormValues['address'].touched && invoiceClientFormValues['address'].invalid)"
                                class="invalid-tooltip">
                                <div *ngIf="invoiceClientFormValues['address'].errors?.['required']">
                                    Company Address is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Phone Number</label>
                            <div class="input-group flex-nowrap">
                                <span class="input-group-text" id="basic-addon1">+254</span>
                                <input type="text" class="form-control" placeholder="722 000 000"
                                    formControlName="phoneNumber" aria-label="Username" aria-describedby="basic-addon1"
                                    [ngClass]="{'is-invalid': invalidPhoneNumber}">
                                <div *ngIf="invalidPhoneNumber" class="invalid-tooltip">
                                    <span>
                                        Write a valid Phone Number.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="email" class="form-label">Email address <span
                                    class="text-danger">*</span></label>
                            <input class="form-control" type="email" id="email" placeholder="Enter an email"
                                minLength="3" maxLength="30" formControlName="email">
                        </div>
                    </div>
                </form>

            </div>

            <div class="card-body">
                <div class="card border border-primary">

                    <div class="card-body">
                        <!-- Invoice To: -->
                        <span>
                            <h5 class="my-0">Invoice To:</h5><br />
                        </span>

                        <!-- Travel : -->

                        <table class="table table-sm table-centered table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th class="p-0 text-muted">Company Name: </th>
                                    <th class="p-0">
                                        <span
                                            *ngIf="invoiceClientForm.value['companyName']">{{invoiceClientForm.value['companyName']}}<br /></span>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="p-0 text-muted">Company Address: </th>
                                    <th class="p-0">
                                        <span
                                            *ngIf="invoiceClientForm.value['address']">{{invoiceClientForm.value['address']}}<br /></span>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="p-0 text-muted">Email: </th>
                                    <th class="p-0">
                                        <span
                                            *ngIf="invoiceClientForm.value['email']">{{invoiceClientForm.value['email']}}<br /></span>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="p-0 text-muted">Phone Number: </th>
                                    <th class="p-0">
                                        <span *ngIf="invoiceClientForm.value['phoneNumber']">+254{{invoiceClientForm.value['phoneNumber']}}<br /></span>
                                    </th>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>

        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">3. Invoice Items :</h4>
            </div>
            <div class="card-body">
                <!-- form -->

                <form name="signup-form" [formGroup]="vistaForm">
                    <div>
                        <div class="input-group input-group-sm">
                            <span class="input-group-text" id="inputGroup-sizing-sm">{{invoiceItemArray.length +
                                1}}</span>
                            <input type="date" class="form-control" id="date" aria-label="Sizing example input"
                                formControlName="date" aria-describedby="inputGroup-sizing-sm">
                            <input type="text" class="form-control" placeholder="Jane Doe" id="name"
                                aria-label="Sizing example input" formControlName="name" minLength="3" maxLength="15"
                                aria-describedby="inputGroup-sizing-sm">
                            <select class="form-select" name="sessionType" formControlName="sessionType"
                                id="sessionType" required>
                                <option *ngFor="let m of sessionTypeArray" [value]="m">
                                    {{m}}
                                </option>
                            </select>
                            <input type="number" min="1" class="form-control" placeholder="1" id="sessionCount"
                                aria-label="Sizing example input" formControlName="sessionCount" min="1" max="10"
                                aria-describedby="inputGroup-sizing-sm">
                            <input type="number" min="1" class="form-control" placeholder="3000" id="rate"
                                aria-label="Sizing example input" formControlName="rate" min="1" max="10000"
                                aria-describedby="inputGroup-sizing-sm">
                            <input type="number" min="1" class="form-control" readonly placeholder="3000" id="itemTotal"
                                aria-label="Sizing example input" formControlName="itemTotal"
                                aria-describedby="inputGroup-sizing-sm">
                            <button class="btn btn-outline-success" *ngIf="!submitInProgress && !updateInProgress"
                                (click)="addInvoiceItem(vistaForm.value)" type="button">Add Item</button>
                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                *ngIf="submitInProgress || updateInProgress">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Vista Fee : -->
                        <span>
                            <h5 class="my-0">Vista Fee :</h5><br />
                        </span>
                        <div class="table-responsive-sm" *ngIf="vistaForm.value">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1 w-10">
                                            No.
                                        </th>
                                        <th class="p-0 px-1 w-20">
                                            Name
                                        </th>
                                        <th class="p-0 px-1 w-30">
                                            Date
                                        </th>
                                        <th class="p-0 px-1 w-30">
                                            Session type
                                        </th>
                                        <th class="p-0 px-1 w-30">
                                            Physiotherapy Session Count
                                        </th>
                                        <th class="p-0 px-1 w-10">
                                            Rate
                                        </th>
                                        <th class="p-0 px-1 w-20">
                                            Total
                                        </th>
                                        <th class="p-0 px-1 w-10"></th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let m of invoiceItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.date">
                                                {{m.date}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.sessionType">
                                                {{m.sessionType}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.sessionCount">
                                                {{m.sessionCount}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.rate">
                                                {{m.rate | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1">
                                            <span *ngIf="m.itemTotal">
                                                {{m.itemTotal | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <th class="p-0 px-1">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeInvoiceItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </th>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="totalForm.value">
                                    <tr><br /></tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0 px-1">
                                            <span>Total</span>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1 text-center">

                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="totalForm.value['totalCost']">
                                                {{totalForm.value['totalCost'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">4. Notes :</h4>
            </div>
            <div class="card-body">
                <!-- form -->



                <div class="row">

                    <form name="signup-form" [formGroup]="noteForm" *ngIf="noteForm">
                        <div>
                            <div class="input-group input-group-sm">
                                <span class="input-group-text" id="inputGroup-sizing-sm">{{notesItemArray.length +
                                    1}}</span>
                                <input class="form-control" type="text" id="description"
                                    placeholder="The airline ticket prices may change depending on time & availability."
                                    required formControlName="description"  minLength="3" maxLength="60"
                                    [ngClass]="{'is-invalid': (formSubmitted && noteFormValues['description'].invalid)||(noteFormValues['description'].touched && noteFormValues['description'].invalid)}">
                                <button class="btn btn-outline-success" *ngIf="!submitInProgress && !updateInProgress"
                                    (click)="addNoteItem(noteForm.value)" type="button">Add Item</button>
                                <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </form>


                    <!-- <form name="signup-form" [formGroup]="noteForm"
                            *ngIf="showProjectDealerOperatingExpenseEdit">
                            <div>
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text"
                                        id="inputGroup-sizing-sm">{{notesItemArray.length + 1}}</span>
                                    <input type="text" class="form-control" placeholder="Name" id="name"
                                        aria-label="Sizing example input" formControlName="name"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <input type="number" min="1" class="form-control" placeholder="Cost" id="total"
                                        aria-label="Sizing example input" formControlName="total"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <button class="btn btn-outline-success"
                                        *ngIf="!submitInProgress && !updateInProgress"
                                        (click)="addDealerOperatingExpenseItem(noteForm.value,'salariesAndBonus')"
                                        type="button">Add Item</button>
                                    <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                        *ngIf="submitInProgress || updateInProgress">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </form> -->
                </div>
            </div>
            <div class="card-body">
                <div class="card border border-primary">
                    <div class="card-body">
                        <!-- Notes To: -->
                        <span>
                            <h5 class="my-0">Notes :</h5><br />
                        </span>
                        <div class="row text-center" *ngIf="!(notesItemArray.length > 0)">
                            <div class="row flex-grow-1">
                                <div class="col">
                                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                                        <h1 class="display-4">Empty</h1>
                                        <p class="lead">No Notes!</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive-sm" *ngIf="(notesItemArray.length > 0)">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1">
                                            No.
                                        </th>
                                        <th class="p-0 px-1">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let m of notesItemArray; let i = index;">
                                        <td class="p-0 px-1">{{i+1}}.</td>
                                        <td class="p-0 px-1">{{m}}</td>
                                        <td class="p-0 px-1">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeNoteItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="my-0">5. Preview :</h4>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-md-12">
                        <div class="card border border-primary">
                            <div class="pricing-header px-0 py-0 text-center">
                                <h1 class="display-6">Invoice</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="card border border-primary" *ngIf="(invoiceClientForm.value['companyName'])">
                            <div class="card-body">
                                <!-- Invoice To: -->
                                <span>
                                    <h5 class="my-0">Invoice To:</h5><br />
                                </span>

                                <!-- Travel : -->

                                <table class="table table-sm table-centered table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <th class="p-0 text-muted">Company Name: </th>
                                            <th class="p-0">
                                                <span
                                                    *ngIf="invoiceClientForm.value['companyName']">{{invoiceClientForm.value['companyName']}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Company Address: </th>
                                            <th class="p-0">
                                                <span
                                                    *ngIf="invoiceClientForm.value['address']">{{invoiceClientForm.value['address']}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Email: </th>
                                            <th class="p-0">
                                                <span
                                                    *ngIf="invoiceClientForm.value['email']">{{invoiceClientForm.value['email']}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Phone Number: </th>
                                            <th class="p-0">
                                                <span *ngIf="invoiceClientForm.value['phoneNumber']">+254{{invoiceClientForm.value['phoneNumber']}}<br /></span>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card border border-primary" *ngIf="companyName || loggedInUserDisplayName">
                            <div class="card-body">
                                <!-- Invoice To: -->
                                <span>
                                    <h5 class="my-0">Invoice From:</h5><br />
                                </span>

                                <table class="table table-sm table-centered table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <th class="p-0 text-muted">Company Name: </th>
                                            <th class="p-0">
                                                <span *ngIf="companyName">{{companyName}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Addressed to: </th>
                                            <th class="p-0">
                                                <span
                                                    *ngIf="loggedInUserDisplayName">{{loggedInUserDisplayName}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Email: </th>
                                            <th class="p-0">
                                                <span *ngIf="companyEmail">{{companyEmail}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Phone Number: </th>
                                            <th class="p-0">
                                                <span *ngIf="companyPhoneNumber">{{companyPhoneNumber}}<br /></span>
                                            </th>
                                        </tr>
                                        <br />
                                        <tr>
                                            <th class="p-0 text-muted">Invoice No: </th>
                                            <th class="p-0">
                                                <span *ngIf="invoiceId">
                                                    {{invoiceId}}<br /></span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="p-0 text-muted">Date: </th>
                                            <th class="p-0">
                                                <span *ngIf="pickedDateFormatted">
                                                    {{pickedDateFormatted}}<br /></span>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>


                            </div>
                        </div>
                    </div>
                </div>



                <div class="card border border-primary" *ngIf="(invoiceItemArray.length>0)">
                    <div class="card-body">

                        <span>
                            <h5 class="my-0">Invoice Items :</h5><br />
                        </span>

                        <div class="table-responsive-sm">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr class="text-muted">
                                        <th class="p-0 px-1 w-10 text-start">
                                            No.
                                        </th>
                                        <th class="p-0 px-1 w-50 text-start">
                                            Name
                                        </th>
                                        <th class="p-0 px-1 w-25 text-start">
                                            Date
                                        </th>
                                        <th class="p-0 px-1 text-end w-25">
                                            Session Type
                                        </th>
                                        <th class="p-0 px-1 text-end w-10">
                                            Session Count
                                        </th>
                                        <th class="p-0 px-1 text-end w-20">
                                            Rate
                                        </th>
                                        <th class="p-0 px-1 text-end w-20">
                                            Total
                                        </th>
                                        <th class="p-0 px-1 text-end w-20"></th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let m of invoiceItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0 px-1 text-start">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-start">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-start">
                                            <span *ngIf="m.date">
                                                {{m.date}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="m.sessionType">
                                                {{m.sessionType}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="m.sessionCount">
                                                {{m.sessionCount}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="m.rate">
                                                {{m.rate | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="m.itemTotal">
                                                {{m.itemTotal | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <th class="p-0 px-1">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeInvoiceItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </th>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="totalForm.value">
                                    <tr><br /></tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0 px-1">
                                            <span>Total</span>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1"></td>
                                        <td class="p-0 px-1 text-center">

                                        </td>
                                        <td class="p-0 px-1 text-end">
                                            <span *ngIf="totalForm.value['totalCost']">
                                                {{totalForm.value['totalCost'] | number:'1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 px-1"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card border border-primary" *ngIf="(notesItemArray.length>0)">
                    <div class="card-body">
                        <!-- Notes -->
                        <span>
                            <h5 class="my-0">Notes :</h5><br />
                        </span>

                        <div class="row text-center" *ngIf="!(notesItemArray.length > 0)">
                            <div class="row flex-grow-1">
                                <div class="col">
                                    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                                        <h1 class="display-4">Empty</h1>
                                        <p class="lead">No Notes!</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive-sm" *ngIf="(notesItemArray.length>0)">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 px-1">
                                            No.
                                        </th>
                                        <th class="p-0 px-1">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let m of notesItemArray; let i = index;">
                                        <td class="p-0 px-1">{{i+1}}.</td>
                                        <td class="p-0 px-1">{{m}}</td>
                                        <td class="p-0 px-1">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeNoteItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>







            </div>
        </div>

    </div>

    <div class="card-footer">
        <div class="card mb-0 bg-success text-white" role="button" (click)="navigateTo('invoicePDF')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-download-outline"></i> Print Invoice (PDF)</h4>
                </div>
            </div>
        </div>
    </div>


</div>