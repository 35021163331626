import { CommonModule, formatCurrency, formatDate } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@firebase/auth-types';
import { NgbAlert, NgbAlertModule, NgbButtonsModule, NgbModal, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, first } from 'rxjs';
import { QuotationItem } from 'src/app/core/models-two/QuotationItem';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { UserService } from 'src/app/core/service/user.service';
import { VistaServicesService } from 'src/app/core/service/vista-services.service';
import { AdvancedTableComponent, Column } from 'src/app/shared/advanced-table/advanced-table.component';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';
import { SortEvent } from 'src/app/shared/advanced-table/sortable.directive';
import { BreadcrumbItem } from 'src/app/shared/page-title/page-title.model';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Volkhov } from 'src/assets/fonts/Volkhov-normal';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-quotation-list',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    WidgetModule,
    NgbButtonsModule,
    NgbTooltipModule,
  ],
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.scss']
})
export class QuotationListComponent implements OnInit, OnDestroy {


  subscription: Subscription = new Subscription();
  today: number = Date.now();
  pageTitle: BreadcrumbItem[] = [];
  //projectArray!: Client[];
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize: number = 5;
  selectAll: boolean = false;
  loading: boolean = false;
  columns: Column[] = [];
  @ViewChild('profileModal', { static: false }) private profileModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('advancedTable') advancedTable!: AdvancedTableComponent;

  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  loggedInUser: User;
  quotationObject: QuotationItem;
  quotationArray;
  userId: any;

  destinationLatitude: number;
  destinationLongitude: number;
  destinationZoom: number;
  googleLocationAddressLong: string;
  googleLocationAddressShort: any;
  profilePicture: any;
  invalidPhoneNumber: boolean;
  pickedImage: any;

  error: string;
  showForm: boolean = false;
  userCategoryViewChoice: number = 1;
  dateNow2: any;
  dateNow3: Date;
  newInviteId: any;
  defaultQuotationArray: any[] = [];
  defaultQuotationObject: any;
  importedQuotationArray: QuotationItem[] = [];
  showImportPanel: boolean = false;
  disableDownloadButton: boolean = false;
  alphabeticalQuotationArray: QuotationItem[] = [];
  workingQuotationArray: QuotationItem[] = [];
  dateToday: string = "";
  pickedQuotationId: string = "";
  pickedQuotationClientName: string;
  pickedQuotationDate: number = 0;
  pickedQuotationPreparedBy: string = "";
  pickedQuotationClientEmail: string = "";
  pickedQuotationClientFirstName: string = "";
  pickedQuotationClientPhoneNumber: number = 0;
  pickedQuotationClientOtherName: string = "";
  pickedQuotationClientSurname: string = "";
  pickedQuotationKesToUSDExchangeRate: number = 0;
  pickedQuotationTreatmentDescription: string = "";
  pickedQuotationTreatmentCostUSD: number = 0;
  pickedQuotationTreatmentCostKES: number = 0;
  pickedQuotationAccommodationDescription: string = "";
  pickedQuotationAccommodationPersonCount: number = 0;
  pickedQuotationAccommodationDayCount: number = 0;
  pickedQuotationAccommodationCostPerPersonUSD: number = 0;
  pickedQuotationAccommodationCostPerPersonKES: number = 0;
  pickedQuotationAccommodationCostUSD: number = 0;
  pickedQuotationAccommodationCostKES: number = 0;
  pickedQuotationVisaDescription: string = "";
  pickedQuotationVisaApplicantsCount: number = 0;
  pickedQuotationVisaCostPerVisaUSD: number = 0;
  pickedQuotationVisaCostPerVisaKES: number = 0;
  pickedQuotationVisaCostUSD: number = 0;
  pickedQuotationVisaCostKES: number = 0;
  pickedQuotationVistaDescription: string = "";
  pickedQuotationVistaApplicantsCount: number = 0;
  pickedQuotationVistaCostPerPersonUSD: number = 0;
  pickedQuotationVistaCostPerPersonKES: number = 0;
  pickedQuotationVistaCostUSD: number = 0;
  pickedQuotationVistaCostKES: number = 0;
  pickedQuotationQuotationTotalCostUSD: number = 0;
  pickedQuotationQuotationTotalCostKES: number = 0;
  pickedQuotationTravelAirlineName: string = "";
  pickedQuotationTravelAirlineTicketCount: number = 0;
  pickedQuotationTravelAirlineCostPerTicketUSD: number = 0;
  pickedQuotationTravelAirlineCostPerTicketKES: number = 0;
  pickedQuotationTravelAirlineCostUSD: number = 0;
  pickedQuotationTravelAirlineCostKES: number = 0;
  pickedQuotationNotes: string[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private vistaService: VistaServicesService,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
          } else {
          }
        }
      })
    );
  }

  ngOnInit(): void {

    // get user data
    this.getQuotationList();
    // initialize advance table 
    this.initAdvancedTableData();

  }

  ngAfterViewInit(): void {

  }


  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  getQuotationList() {
    //this.petroleumProductArray = users;
    this.subscription.add(
      this.vistaService.getQuotationArray().pipe().subscribe((quotationList: any) => {
        if (quotationList) {
          this.workingQuotationArray = [];
          this.defaultQuotationArray = this.quotationArray = quotationList;

          // Arranged Alphabetically
          quotationList.forEach(element => {
            this.workingQuotationArray.push(element);
          });

          this.alphabeticalQuotationArray = this.workingQuotationArray.sort((a: any, b: any) => (a.clientFirstName).localeCompare(b.clientFirstName));

        }
      })
    );

    this.subscription.add(
      this.vistaService.getQuotationObject().pipe().subscribe((quotationList) => {
        if (quotationList) {
          this.defaultQuotationObject = this.quotationObject = quotationList;
        }
      })
    );
  }

  /**
   * initialize advance table columns
   */
  initAdvancedTableData(): void {
    this.columns = [
      {
        name: 'name',
        label: 'Name',
        formatter: (p: QuotationItem) => {
          let firstName = "";
          let otherName = "";
          let surname = "";
          if (p.clientFirstName) {
            firstName = p.clientFirstName;
          };
          if (p.clientOtherName) {
            otherName = p.clientOtherName;
          };
          if (p.clientSurname) {
            surname = p.clientSurname;
          };
          return `${firstName} ${surname} ${otherName}`;
        },
      },
      {
        name: 'id',
        label: 'Id',
        formatter: (p: QuotationItem) => p.quotationId,
      },
      {
        name: 'created',
        label: 'Date',
        formatter: (p: QuotationItem) => {
          if (p.date) {
            return (new Date(p.date)).toLocaleString();
          } else {

          }
        },
      },
      {
        name: 'quick link',
        label: 'Quick Links',
        formatter: this.userActionFormatter.bind(this),
        sort: false
      }
    ]
  }

  /**
   * handles operations that need to be performed after loading table
   */
  handleTableLoad(): void {
    // user cell
    document.querySelectorAll('.view-edit').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to report page
        // this.router.navigate([`portal/quotation/${e.id}`]);
        // this.printQuotationPDF();
        this.loadPickedItem(e.id);
      });
    })
    document.querySelectorAll('.delete').forEach((e) => {
      e.addEventListener("click", () => {
        if (confirm("Are you sure you want to delete this quotation?")) {
          // Move item to petroleumProduct/delete
          this.deleteQuotation(e.id);
        }
      });
    })
  }

  loadPickedItem(id: string) {
    if (this.quotationArray) {

      let indexAB = 0;
      this.quotationArray.forEach(element => {
        if (element.quotationId === id) {
          if (this.quotationArray[indexAB]) {
            if (this.quotationArray[indexAB].quotationId) { this.pickedQuotationId = this.quotationArray[indexAB].quotationId };
            if (this.quotationArray[indexAB].date) { this.pickedQuotationDate = this.quotationArray[indexAB].date };
            if (this.quotationArray[indexAB].preparedBy) { this.pickedQuotationPreparedBy = this.quotationArray[indexAB].preparedBy };
            if (this.quotationArray[indexAB].clientFirstName) { this.pickedQuotationClientFirstName = this.quotationArray[indexAB].clientFirstName };
            if (this.quotationArray[indexAB].clientOtherName) { this.pickedQuotationClientOtherName = this.quotationArray[indexAB].clientOtherName };
            if (this.quotationArray[indexAB].clientSurname) { this.pickedQuotationClientSurname = this.quotationArray[indexAB].clientSurname };
            this.pickedQuotationClientName = `${this.pickedQuotationClientFirstName} ${this.pickedQuotationClientOtherName} ${this.pickedQuotationClientSurname}`
            if (this.quotationArray[indexAB].clientEmail) { this.pickedQuotationClientEmail = this.quotationArray[indexAB].clientEmail };
            if (this.quotationArray[indexAB].clientPhoneNumber) { this.pickedQuotationClientPhoneNumber = this.quotationArray[indexAB].clientPhoneNumber };
            if (this.quotationArray[indexAB].kesToUSDExchangeRate) { this.pickedQuotationKesToUSDExchangeRate = this.quotationArray[indexAB].kesToUSDExchangeRate };
            if (this.quotationArray[indexAB].treatmentDescription) { this.pickedQuotationTreatmentDescription = this.quotationArray[indexAB].treatmentDescription };
            if (this.quotationArray[indexAB].treatmentCostUSD) { this.pickedQuotationTreatmentCostUSD = this.quotationArray[indexAB].treatmentCostUSD };
            if (this.quotationArray[indexAB].treatmentCostKES) { this.pickedQuotationTreatmentCostKES = this.quotationArray[indexAB].treatmentCostKES };
            if (this.quotationArray[indexAB].accommodationDescription) { this.pickedQuotationAccommodationDescription = this.quotationArray[indexAB].accommodationDescription };
            if (this.quotationArray[indexAB].accommodationPersonCount) { this.pickedQuotationAccommodationPersonCount = this.quotationArray[indexAB].accommodationPersonCount };
            if (this.quotationArray[indexAB].accommodationDayCount) { this.pickedQuotationAccommodationDayCount = this.quotationArray[indexAB].accommodationDayCount };
            if (this.quotationArray[indexAB].accommodationCostPerPersonUSD) { this.pickedQuotationAccommodationCostPerPersonUSD = this.quotationArray[indexAB].accommodationCostPerPersonUSD };
            if (this.quotationArray[indexAB].accommodationCostPerPersonKES) { this.pickedQuotationAccommodationCostPerPersonKES = this.quotationArray[indexAB].accommodationCostPerPersonKES };
            if (this.quotationArray[indexAB].accommodationCostUSD) { this.pickedQuotationAccommodationCostUSD = this.quotationArray[indexAB].accommodationCostUSD };
            if (this.quotationArray[indexAB].accommodationCostKES) { this.pickedQuotationAccommodationCostKES = this.quotationArray[indexAB].accommodationCostKES };
            if (this.quotationArray[indexAB].visaDescription) { this.pickedQuotationVisaDescription = this.quotationArray[indexAB].visaDescription };
            if (this.quotationArray[indexAB].visaApplicantsCount) { this.pickedQuotationVisaApplicantsCount = this.quotationArray[indexAB].visaApplicantsCount };
            if (this.quotationArray[indexAB].visaCostPerVisaUSD) { this.pickedQuotationVisaCostPerVisaUSD = this.quotationArray[indexAB].visaCostPerVisaUSD };
            if (this.quotationArray[indexAB].visaCostPerVisaKES) { this.pickedQuotationVisaCostPerVisaKES = this.quotationArray[indexAB].visaCostPerVisaKES };
            if (this.quotationArray[indexAB].visaCostUSD) { this.pickedQuotationVisaCostUSD = this.quotationArray[indexAB].visaCostUSD };
            if (this.quotationArray[indexAB].visaCostKES) { this.pickedQuotationVisaCostKES = this.quotationArray[indexAB].visaCostKES };
            if (this.quotationArray[indexAB].vistaDescription) { this.pickedQuotationVistaDescription = this.quotationArray[indexAB].vistaDescription };
            if (this.quotationArray[indexAB].vistaApplicantsCount) { this.pickedQuotationVistaApplicantsCount = this.quotationArray[indexAB].vistaApplicantsCount };
            if (this.quotationArray[indexAB].vistaCostPerPersonUSD) { this.pickedQuotationVistaCostPerPersonUSD = this.quotationArray[indexAB].vistaCostPerPersonUSD };
            if (this.quotationArray[indexAB].vistaCostPerPersonKES) { this.pickedQuotationVistaCostPerPersonKES = this.quotationArray[indexAB].vistaCostPerPersonKES };
            if (this.quotationArray[indexAB].vistaCostUSD) { this.pickedQuotationVistaCostUSD = this.quotationArray[indexAB].vistaCostUSD };
            if (this.quotationArray[indexAB].vistaCostKES) { this.pickedQuotationVistaCostKES = this.quotationArray[indexAB].vistaCostKES };
            if (this.quotationArray[indexAB].quotationTotalCostUSD) { this.pickedQuotationQuotationTotalCostUSD = this.quotationArray[indexAB].quotationTotalCostUSD };
            if (this.quotationArray[indexAB].quotationTotalCostKES) { this.pickedQuotationQuotationTotalCostKES = this.quotationArray[indexAB].quotationTotalCostKES };
            if (this.quotationArray[indexAB].travelAirlineName) { this.pickedQuotationTravelAirlineName = this.quotationArray[indexAB].travelAirlineName };
            if (this.quotationArray[indexAB].travelAirlineTicketCount) { this.pickedQuotationTravelAirlineTicketCount = this.quotationArray[indexAB].travelAirlineTicketCount };
            if (this.quotationArray[indexAB].travelAirlineCostPerTicketUSD) { this.pickedQuotationTravelAirlineCostPerTicketUSD = this.quotationArray[indexAB].travelAirlineCostPerTicketUSD };
            if (this.quotationArray[indexAB].travelAirlineCostPerTicketKES) { this.pickedQuotationTravelAirlineCostPerTicketKES = this.quotationArray[indexAB].travelAirlineCostPerTicketKES };
            if (this.quotationArray[indexAB].travelAirlineCostUSD) { this.pickedQuotationTravelAirlineCostUSD = this.quotationArray[indexAB].travelAirlineCostUSD };
            if (this.quotationArray[indexAB].travelAirlineCostKES) { this.pickedQuotationTravelAirlineCostKES = this.quotationArray[indexAB].travelAirlineCostKES };
    
            if (this.quotationArray[indexAB].notes) { this.pickedQuotationNotes = this.quotationArray[indexAB].notes };
    
            this.printQuotationPDF();
          }
        }
        indexAB += 1;
      });

      
    }
  };

  printQuotationPDF() {

    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');

    //
    this.dateToday = `${day}-${month}-${year}`;

    this.notify("Downloading Quotation ...");

    const param = {
      outputType: "save",
      returnJsPDFDocObject: false,
      fileName: `${this.pickedQuotationClientName} - Quotation - ${this.dateToday}.pdf`,
      orientationLandscape: false,
      compress: true,
      pageEnable: true,
      pageLabel: "Page",
    };

    var doc = new jsPDF(param.orientationLandscape ? "landscape" : "p", "mm", "a4", param.compress);

    // add the font to jsPDF
    doc.addFileToVFS("Volkhov.ttf", Volkhov);
    doc.addFont('Volkhov.ttf', 'Volkhov', 'normal');

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    var colorYellow = "#CDAE59";
    var colorBlue = "#353E59";
    var colorRed = "#fa5c7c";
    var colorGreen = "#0acf97";
    var colorWhite = "#ffffff";
    //starting at 15mm
    var currentHeight = 15;
    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
      headerTextSize: 16,
      labelTextSize: 12,
      fieldTextSize: 11,
      lineHeight: 9,
      subLineHeight: 5,
      headerLineHeight: 6,
      headerSubLineHeight: 4,
      headerFieldTextSize: 10,
    };

    var addHeader = function () {
      currentHeight = 15;

      // Background Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_43_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        0,
        -1,
        210,
        30
      );

      // Logo Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/logo_315_x_315.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        docWidth - 60,
        currentHeight - 3,
        35,
        35
      );

      // Logo Image 2
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_logo_350_x_350_center_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        45,
        90,
        120,
        120
      );

      doc.setFontSize(20);
      doc.setTextColor(colorWhite);
      doc.text(`Vista Services, Kenya`, 45, 15, { align: 'center' },);
      doc.setTextColor(colorBlack);

      //line breaker after logo & business info
      // doc.line(10, 45, docWidth - 10, 45);
      currentHeight = 45;
    }

    addHeader();

    // Start
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.setFontSize(12);

    currentHeight += pdfConfig.lineHeight;

    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(25, currentHeight - 7, docWidth - 50, 10, 0, 0);
    doc.setDrawColor(0);

    doc.setFontSize(16);
    doc.text(`Approximate Quotation`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(12);
    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(25, currentHeight - 2, docWidth - 50, 10, 0, 0);
    doc.setDrawColor(0);

    currentHeight += pdfConfig.subLineHeight;
    doc.setTextColor(colorGray);
    doc.text(`Patient Name `, docWidth / 2, currentHeight, { align: 'right' },);
    doc.text(`:`, docWidth / 2, currentHeight, { align: 'center' },);

    if (this.pickedQuotationClientName) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.pickedQuotationClientName}`, docWidth / 2, currentHeight, { align: 'left' },);
    };

    doc.setFontSize(12);
    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(25, currentHeight + 3, docWidth - 50, 80, 0, 0);
    doc.roundedRect(25, currentHeight + 3, docWidth - 50, 12, 0, 0);
    doc.roundedRect(25, currentHeight + 3, 90, 12, 0, 0);
    doc.roundedRect(115, currentHeight + 3, 70, 6, 0, 0);

    currentHeight += pdfConfig.lineHeight;

    doc.roundedRect(150, currentHeight, 35, 6, 0, 0);
    doc.setDrawColor(0);

    doc.setTextColor(colorGray);
    doc.text(`Package Details`, docWidth / 3, (currentHeight + 1), { align: 'center' },);
    doc.text(`Cost`, (docWidth - 59), (currentHeight - 1), { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.text(`USD`, (docWidth - 77), currentHeight, { align: 'center' },);
    doc.text(`KES`, (docWidth - 40), currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.text(`Travel`, 27, currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    if ((this.pickedQuotationTravelAirlineName) || (this.pickedQuotationTravelAirlineTicketCount)) { doc.text(`1. ${this.pickedQuotationTravelAirlineName} for ${this.pickedQuotationTravelAirlineTicketCount} persons.`, 29, currentHeight, { align: 'left' },); }
    if (this.pickedQuotationTravelAirlineCostUSD) { doc.text(`${formatCurrency((this.pickedQuotationTravelAirlineCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); }
    if (this.pickedQuotationTravelAirlineCostKES) { doc.text(`${formatCurrency((this.pickedQuotationTravelAirlineCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },); };
    currentHeight += pdfConfig.subLineHeight;

    doc.setTextColor(colorGray);
    doc.text(`Treatment`, 27, currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    if (this.pickedQuotationTreatmentDescription) { doc.text(`2. ${this.pickedQuotationTreatmentDescription}.`, 29, currentHeight, { align: 'left' },); }
    if (this.pickedQuotationTreatmentCostUSD) { doc.text(`${formatCurrency((this.pickedQuotationTreatmentCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); }
    if (this.pickedQuotationTreatmentCostKES) { doc.text(`${formatCurrency((this.pickedQuotationTreatmentCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },); };
    currentHeight += pdfConfig.subLineHeight;

    doc.setTextColor(colorGray);
    doc.text(`Accommodation`, 27, currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    if (this.pickedQuotationAccommodationDescription) { doc.text(`3. ${this.pickedQuotationAccommodationDescription}`, 29, currentHeight, { align: 'left' },); };
    if (this.pickedQuotationAccommodationCostUSD) { doc.text(`${formatCurrency((this.pickedQuotationAccommodationCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); }
    if (this.pickedQuotationAccommodationCostKES) { doc.text(`${formatCurrency((this.pickedQuotationAccommodationCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },); };
    currentHeight += pdfConfig.subLineHeight;
    if (this.pickedQuotationAccommodationPersonCount) { doc.text(`for ${this.pickedQuotationAccommodationPersonCount} persons.`, 34, currentHeight, { align: 'left' },); };
    currentHeight += pdfConfig.subLineHeight;

    doc.setTextColor(colorGray);
    doc.text(`Visa`, 27, currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    if ((this.pickedQuotationVisaDescription) || (this.pickedQuotationVisaApplicantsCount)) { doc.text(`4. ${this.pickedQuotationVisaDescription} for ${this.pickedQuotationVisaApplicantsCount} persons.`, 29, currentHeight, { align: 'left' },); }
    if (this.pickedQuotationVisaCostUSD) { doc.text(`${formatCurrency((this.pickedQuotationVisaCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); }
    if (this.pickedQuotationVisaCostKES) { doc.text(`${formatCurrency((this.pickedQuotationVisaCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },); };
    currentHeight += pdfConfig.subLineHeight;

    doc.setTextColor(colorGray);
    doc.text(`Vista Services`, 27, currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    if ((this.pickedQuotationVistaDescription)) { doc.text(`5. ${this.pickedQuotationVistaDescription}.`, 29, currentHeight, { align: 'left' },); }
    if (this.pickedQuotationVistaCostUSD) { doc.text(`${formatCurrency((this.pickedQuotationVistaCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); }
    if (this.pickedQuotationVistaCostKES) { doc.text(`${formatCurrency((this.pickedQuotationVistaCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },); };
    currentHeight += pdfConfig.lineHeight;

    doc.text(`Total`, 90, currentHeight, { align: 'left' },);
    if (this.pickedQuotationQuotationTotalCostUSD) { doc.text(`${formatCurrency((this.pickedQuotationQuotationTotalCostUSD), 'en-US', '', '', '1.2-2')}`, docWidth - 65, currentHeight, { align: 'right' },); };
    if (this.pickedQuotationQuotationTotalCostKES) {
      doc.setFontSize(12);
      doc.text(`${formatCurrency((this.pickedQuotationQuotationTotalCostKES), 'en-US', '', '', '1.2-2')}`, docWidth - 26, currentHeight, { align: 'right' },);
      doc.setFontSize(12);
    };
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(12);
    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(25, currentHeight + 3, docWidth - 50, 30, 1, 1);
    doc.setDrawColor(0);

    currentHeight += pdfConfig.lineHeight;
    doc.text(`Notes`, 27, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.subLineHeight;

    if (this.pickedQuotationNotes) {
      if (this.pickedQuotationNotes.length > 0) {
        let xy = this.pickedQuotationNotes;
        let indexAB = 1;
        xy.forEach(element => {
          doc.text(`${indexAB}. ${element}`, 29, currentHeight, { align: 'left' },);
          currentHeight += pdfConfig.subLineHeight;
          indexAB += 1;
        });
      } else {
        currentHeight += pdfConfig.subLineHeight;
      };
    } else {
      currentHeight += pdfConfig.subLineHeight;
    };

    // Stamp, Date & Signature.
    currentHeight += pdfConfig.lineHeight;

    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
    doc.setDrawColor(0);

    currentHeight += pdfConfig.lineHeight;
    doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
    doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
    currentHeight += pdfConfig.lineHeight;
    doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

    if (this.pickedQuotationDate) {
      doc.text(` ${formatDate(this.pickedQuotationDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
    } else {
      doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
    }

    // stamp_750_x287
    var imageHeader = new Image();
    imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
    doc.addImage(
      imageHeader,
      "JPEG",
      120,
      currentHeight - 25,
      75,
      28.7
    );




    //#region Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(pdfConfig.headerFieldTextSize);
        doc.setTextColor(colorWhite);

        if (param.pageEnable) {
          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            0,
            doc.internal.pageSize.height - 29,
            210,
            30
          );

          doc.text("Park Medical Center (PMC), 3rd Parklands Avenue, 2nd Floor, Nairobi.", 15, docHeight - 12, { align: 'left' });
          doc.text("+254 718 862 006", 160, docHeight - 12, { align: 'left' });
          doc.text("www.vistaservices.co.ke", 15, docHeight - 3, { align: 'left' });
          doc.text("www.facebook.com/VistaServicesKenya", 116, docHeight - 3, { align: 'left' });
          doc.setTextColor(colorBlack);
          doc.text(
            param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
            docWidth - 30,
            doc.internal.pageSize.height - 35,
            { align: 'left' }
          );
          doc.setTextColor(colorWhite);

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_globe_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            8,
            doc.internal.pageSize.height - 7,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_location_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            8,
            doc.internal.pageSize.height - 16,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_phone_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            154,
            doc.internal.pageSize.height - 16,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_facebook_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            110,
            doc.internal.pageSize.height - 7,
            5,
            5
          );
        }

      }
    }

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        0,
        doc.internal.pageSize.height - 29,
        210,
        30
      );

      doc.setFontSize(pdfConfig.fieldTextSize);
      doc.setTextColor(colorWhite);
      doc.text("Park Medical Center (PMC), 3rd Parklands Avenue, 2nd Floor, Nairobi.", 15, docHeight - 12, { align: 'left' });
      doc.text("+254 718 862 006", 160, docHeight - 12, { align: 'left' });
      doc.text("www.vistaservices.co.ke", 15, docHeight - 3, { align: 'left' });
      doc.text("www.facebook.com/VistaServicesKenya", 116, docHeight - 3, { align: 'left' });
      doc.setTextColor(colorBlack);
      doc.text(
        param.pageLabel + " 1 / 1",
        docWidth - 30,
        doc.internal.pageSize.height - 35,
        { align: 'left' }
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_globe_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        8,
        doc.internal.pageSize.height - 7,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_location_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        8,
        doc.internal.pageSize.height - 16,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_phone_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        154,
        doc.internal.pageSize.height - 16,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_facebook_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        110,
        doc.internal.pageSize.height - 7,
        5,
        5
      );

      doc.setTextColor(colorWhite);
    }
    //#endregion


    let returnObj = {
      pagesNumber: doc.getNumberOfPages(),
    };



    if (param.outputType === "save") {
      // this.disableDownloadButton = false;
      doc.save(param.fileName);
    }

    return returnObj;
  };

  deleteQuotation(id: string) {

    let quotationName = "";
    quotationName = `${this.quotationArray[id].firstName} ${this.quotationArray[id].otherName} ${this.quotationArray[id].surname}`;

    this.subscription.add(
      this.vistaService.deleteItem("quotation", id)
        .pipe(first()).subscribe((result) => {

          //Get User Data
          this.show_alert = true;
          this.alert_message = `Quotation for ${quotationName} Deleted!`;
          this.alert_type = "success";

          this.toastr.success(`Quotation for ${quotationName} Deleted!`, "Info!");

          setTimeout(() => {
            this.show_alert = false;
            this.selfClosingAlert.close();
          }, 3000);

        },
          (error: string) => {
            this.toastr.error(`${quotationName} was not Deleted, error: ${error}!`, "Info!");
            this.error = error;
            this.loading = false;
          }));
  }

  // table action cell formatter
  userActionFormatter(a: QuotationItem): SafeHtml {
    if (a.quotationId) {
      return this.sanitizer.bypassSecurityTrustHtml(
        `<button id="${a.quotationId}" class="btn btn-sm btn-outline-secondary mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 view-edit" type="button" *ngIf="!updateInProgress"> <i class=""></i>Download PDF</button>
            <button id="${a.quotationId}" class="btn btn-sm btn-outline-danger mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 delete" type="button" *ngIf="!updateInProgress"> <i class=""></i>X</button>`
      )
    }

    // <button id="${a.quotationId}" class="btn btn-sm btn-outline-secondary mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 postInvestment" type="button" *ngIf="!updateInProgress"> <i class=""></i>Post Investment</button>
  }

  numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  // compares two cell valueuser?: QuotationItem;
  user_complete?: boolean;
  compare(v1: string | number | boolean, v2: string | number | boolean): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  /**
   * Sort the table data
   * @param event column name,sort direction
   */
  sort(event: SortEvent): void {
    if (event.direction === '') {
      this.quotationArray = this.defaultQuotationArray;
      //this.getQuotationList();
    } else {
      this.quotationArray = [...this.quotationArray].sort((a, b) => {
        const res = this.compare(a["0"][event.column], b["0"][event.column]);
        return event.direction === 'asc' ? res : -res;
      });
    }
  }

  /**
 * Table Data Match with Search input
 * @param tableRow Table row
 * @param term Search the value
 */
  matches(tableRow: QuotationItem, term: string) {
    return tableRow.quotationId?.toLowerCase().includes(term)
      || tableRow.clientFirstName?.toLowerCase().includes(term)
      || tableRow.clientOtherName?.toLowerCase().includes(term)
      || tableRow.clientSurname?.toLowerCase().includes(term)
      || tableRow.clientEmail?.toLowerCase().includes(term);
  };

  /**
   * Search Method
  */
  searchData(searchTerm: string): void {
    if (searchTerm === '') {
      // this.getQuotationList();
      this.quotationArray = this.defaultQuotationArray;
    }
    else {
      let updatedData = this.quotationArray = this.defaultQuotationArray;
      //  filter
      updatedData = updatedData.filter(a => this.matches(a, searchTerm));
      this.quotationArray = updatedData;
    }
  }

  hasChanged($event: any) {
    // Clear all other address details?
  }



  createRecord() {
    this.router.navigate([`portal/quotation/create`]);
  }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}


