import { CommonModule, formatCurrency, formatDate } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@firebase/auth-types';
import { NgbAlert, NgbAlertModule, NgbButtonsModule, NgbModal, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, first } from 'rxjs';
import { ReceiptItem } from 'src/app/core/models-two/ReceiptItem';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { UserService } from 'src/app/core/service/user.service';
import { VistaServicesService } from 'src/app/core/service/vista-services.service';
import { AdvancedTableComponent, Column } from 'src/app/shared/advanced-table/advanced-table.component';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';
import { SortEvent } from 'src/app/shared/advanced-table/sortable.directive';
import { BreadcrumbItem } from 'src/app/shared/page-title/page-title.model';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Volkhov } from 'src/assets/fonts/Volkhov-normal';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-receipt-list',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    WidgetModule,
    NgbButtonsModule,
    NgbTooltipModule,
  ],
  templateUrl: './receipt-list.component.html',
  styleUrls: ['./receipt-list.component.scss']
})
export class ReceiptListComponent implements OnInit, OnDestroy {


  subscription: Subscription = new Subscription();
  today: number = Date.now();
  pageTitle: BreadcrumbItem[] = [];
  //projectArray!: Client[];
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize: number = 5;
  selectAll: boolean = false;
  loading: boolean = false;
  columns: Column[] = [];
  @ViewChild('profileModal', { static: false }) private profileModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('advancedTable') advancedTable!: AdvancedTableComponent;

  formSubmitted: boolean = false;

  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  loggedInUser: User;
  receiptObject: ReceiptItem;
  receiptArray;
  userId: any;

  destinationLatitude: number;
  destinationLongitude: number;
  destinationZoom: number;
  googleLocationAddressLong: string;
  googleLocationAddressShort: any;
  profilePicture: any;
  invalidPhoneNumber: boolean;
  pickedImage: any;

  error: string;
  showForm: boolean = false;
  userCategoryViewChoice: number = 1;
  dateNow2: any;
  dateNow3: Date;
  newInviteId: any;
  defaultReceiptArray: any[] = [];
  defaultReceiptObject: any;
  importedReceiptArray: ReceiptItem[] = [];
  showImportPanel: boolean = false;
  disableDownloadButton: boolean = false;
  alphabeticalReceiptArray: ReceiptItem[] = [];
  workingReceiptArray: ReceiptItem[] = [];
  dateToday: string;
  pickedReceiptId: any;
  pickedReceiptPreparedBy: any;
  pickedReceiptClientOtherName: any;
  pickedReceiptClientFirstName: any;
  pickedReceiptDate: any;
  pickedReceiptClientSurname: any;
  pickedReceiptClientName: string;
  pickedReceiptClientEmail: any;
  pickedReceiptClientPhoneNumber: any;
  pickedReceiptReceiptItems: any;
  pickedReceiptReceiptTotalCost: any;
  pickedReceiptNotes: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private vistaService: VistaServicesService,
    private clockService: ClockService,
  ) {
    this.loadingAnimationFourSeconds();
    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
          } else {
          }
        }
      })
    );
  }

  ngOnInit(): void {

    // get user data
    this.getReceiptList();
    // initialize advance table 
    this.initAdvancedTableData();

  }

  ngAfterViewInit(): void {

  }


  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  getReceiptList() {
    //this.petroleumProductArray = users;
    this.subscription.add(
      this.vistaService.getReceiptArray().pipe().subscribe((receiptList: any) => {
        if (receiptList) {
          this.workingReceiptArray = [];
          this.defaultReceiptArray = this.receiptArray = receiptList;

          // Arranged Alphabetically
          receiptList.forEach(element => {
            this.workingReceiptArray.push(element);
          });

          this.alphabeticalReceiptArray = this.workingReceiptArray.sort((a: any, b: any) => (a.clientFirstName).localeCompare(b.clientFirstName));

        }
      })
    );

    this.subscription.add(
      this.vistaService.getReceiptObject().pipe().subscribe((receiptList) => {
        if (receiptList) {
          this.defaultReceiptObject = this.receiptObject = receiptList;
        }
      })
    );
  }

  /**
   * initialize advance table columns
   */
  initAdvancedTableData(): void {
    this.columns = [
      {
        name: 'name',
        label: 'Name',
        formatter: (p: ReceiptItem) => {
            let firstName = "";
            let otherName = "";
            let surname = "";
            if (p.clientFirstName) {
              firstName = p.clientFirstName;
            };
            if (p.clientOtherName) {
              otherName = p.clientOtherName;
            };
            if (p.clientSurname) {
              surname = p.clientSurname;
            };
            return `${firstName} ${surname} ${otherName}`;
        },
      },
      {
        name: 'id',
        label: 'Id',
        formatter: (p: ReceiptItem) => p.receiptId,
      },
      {
        name: 'created',
        label: 'Date',
        formatter: (p: ReceiptItem) => {
          if (p.date) {
            return (new Date(p.date)).toLocaleString();
          } else {

          }
        },
      },
      {
        name: 'quick link',
        label: 'Quick Links',
        formatter: this.userActionFormatter.bind(this),
        sort: false
      }
    ]
  }

  /**
   * handles operations that need to be performed after loading table
   */
  handleTableLoad(): void {
    // user cell
    document.querySelectorAll('.view-edit').forEach((e) => {
      e.addEventListener("click", () => {
        //Navigate to report page
        // this.router.navigate([`portal/receipt/${e.id}`]);
        // this.printReceiptPDF();
        this.loadPickedItem(e.id);
      });
    })
    document.querySelectorAll('.delete').forEach((e) => {
      e.addEventListener("click", () => {
        if (confirm("Are you sure you want to delete this receipt?")) {
          // Move item to petroleumProduct/delete
          this.deleteReceipt(e.id);
        }
      });
    })
  }

  loadPickedItem(id: string) {
    if (this.receiptArray) {

      let indexAB = 0;
      this.receiptArray.forEach(element => {
        if (element.receiptId === id) {

          if (this.receiptArray[indexAB]) {
            if (this.receiptArray[indexAB].receiptId) { this.pickedReceiptId = this.receiptArray[indexAB].receiptId };
            if (this.receiptArray[indexAB].date) { this.pickedReceiptDate = this.receiptArray[indexAB].date };
            if (this.receiptArray[indexAB].preparedBy) { this.pickedReceiptPreparedBy = this.receiptArray[indexAB].preparedBy };
            if (this.receiptArray[indexAB].clientFirstName) { this.pickedReceiptClientFirstName = this.receiptArray[indexAB].clientFirstName };
            if (this.receiptArray[indexAB].clientOtherName) { this.pickedReceiptClientOtherName = this.receiptArray[indexAB].clientOtherName };
            if (this.receiptArray[indexAB].clientSurname) { this.pickedReceiptClientSurname = this.receiptArray[indexAB].clientSurname };
            this.pickedReceiptClientName = `${this.pickedReceiptClientFirstName} ${this.pickedReceiptClientOtherName} ${this.pickedReceiptClientSurname}`
            if (this.receiptArray[indexAB].clientEmail) { this.pickedReceiptClientEmail = this.receiptArray[indexAB].clientEmail };
            if (this.receiptArray[indexAB].clientPhoneNumber) { this.pickedReceiptClientPhoneNumber = this.receiptArray[indexAB].clientPhoneNumber };
            if (this.receiptArray[indexAB].receiptItems) { this.pickedReceiptReceiptItems = this.receiptArray[indexAB].receiptItems };
            if (this.receiptArray[indexAB].receiptTotalCost) { this.pickedReceiptReceiptTotalCost = this.receiptArray[indexAB].receiptTotalCost };
            if (this.receiptArray[indexAB].notes) { this.pickedReceiptNotes = this.receiptArray[indexAB].notes };
    
            this.printReceiptPDF();
          }
          
        }
        indexAB += 1;
      });

      
    }
  };

  printReceiptPDF() {

    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');

    //
    this.dateToday = `${day}-${month}-${year}`;

    this.notify("Downloading Receipt ...");

    const param = {
      outputType: "save",
      returnJsPDFDocObject: false,
      fileName: `${this.pickedReceiptClientName} - Receipt - ${this.dateToday}.pdf`,
      orientationLandscape: false,
      compress: true,
      pageEnable: true,
      pageLabel: "Page",
    };

    var doc = new jsPDF(param.orientationLandscape ? "landscape" : "p", "mm", "a4", param.compress);

    // add the font to jsPDF
    doc.addFileToVFS("Volkhov.ttf", Volkhov);
    doc.addFont('Volkhov.ttf', 'Volkhov', 'normal');

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    var colorYellow = "#CDAE59";
    var colorBlue = "#353E59";
    var colorRed = "#fa5c7c";
    var colorGreen = "#0acf97";
    var colorWhite = "#ffffff";
    //starting at 15mm
    var currentHeight = 15;
    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
      headerTextSize: 16,
      labelTextSize: 12,
      fieldTextSize: 11,
      lineHeight: 9,
      subLineHeight: 5,
      headerLineHeight: 6,
      headerSubLineHeight: 4,
      headerFieldTextSize: 10,
    };

    var addHeader = function () {
      currentHeight = 15;

      // Background Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_43_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        0,
        -1,
        210,
        30
      );

      // Logo Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/logo_315_x_315.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        docWidth - 60,
        currentHeight - 3,
        35,
        35
      );

      // Logo Image 2
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_logo_350_x_350_center_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        45,
        90,
        120,
        120
      );

      doc.setFontSize(20);
      doc.setTextColor(colorWhite);
      doc.text(`Vista Services, Kenya`, 45, 15, { align: 'center' },);
      doc.setTextColor(colorBlack);

      //line breaker after logo & business info
      // doc.line(10, 45, docWidth - 10, 45);
      currentHeight = 45;
    }

    addHeader();

    // Start
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.setFontSize(12);

    currentHeight += pdfConfig.lineHeight;

    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(25, currentHeight - 7, docWidth - 50, 10, 0, 0);
    doc.setDrawColor(0);

    doc.setFontSize(16);
    doc.text(`Receipt`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(10);
    doc.setDrawColor(128, 128, 128);
    doc.roundedRect(125, currentHeight - 2, 60, 35, 0, 0);
    doc.roundedRect(25, currentHeight - 2, 100, 35, 0, 0);
    // doc.roundedRect(25, currentHeight - 2, 160, 35, 0, 0);
    doc.setDrawColor(0);

    currentHeight += pdfConfig.subLineHeight;
    doc.setTextColor(colorBlack);
    doc.text(`Receipt To `, 26, currentHeight, { align: 'left' },);
    doc.text(`Receipt Details `, 129, currentHeight, { align: 'left' },);

    doc.setTextColor(colorGray);
    currentHeight += pdfConfig.subLineHeight;
    doc.text(`Name `, 27, currentHeight, { align: 'left' },);
    doc.text(`:`, 55, currentHeight, { align: 'left' },);
    if (this.pickedReceiptClientName) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.pickedReceiptClientName}`, 57, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };
    doc.text(`Receipt No. `, 130, currentHeight, { align: 'left' },);
    doc.text(`:`, 160, currentHeight, { align: 'left' },);
    if (this.pickedReceiptId) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.pickedReceiptId}`, 162, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };

    currentHeight += pdfConfig.subLineHeight;
    // doc.text(`Address `, 27, currentHeight, { align: 'left' },);
    // doc.text(`:`, 55, currentHeight, { align: 'left' },);
    // if (this.receiptCompanyAddress) {
    //   doc.setTextColor(colorBlack);
    //   doc.text(` ${this.receiptCompanyAddress}`, 57, currentHeight, { align: 'left' },);
    //   doc.setTextColor(colorGray);
    // };
    doc.text(`Receipt Date `, 130, currentHeight, { align: 'left' },);
    doc.text(`:`, 160, currentHeight, { align: 'left' },);
    if (this.pickedReceiptDate) {
      doc.setTextColor(colorBlack);
      doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 162, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };

    currentHeight += pdfConfig.subLineHeight;

    currentHeight += pdfConfig.subLineHeight;
    doc.text(`Email `, 27, currentHeight, { align: 'left' },);
    doc.text(`:`, 55, currentHeight, { align: 'left' },);
    if (this.pickedReceiptClientEmail) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.pickedReceiptClientEmail}`, 57, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };

    currentHeight += pdfConfig.subLineHeight;
    doc.text(`Phone No. `, 27, currentHeight, { align: 'left' },);
    doc.text(`:`, 55, currentHeight, { align: 'left' },);
    if (this.pickedReceiptClientPhoneNumber) {
      doc.setTextColor(colorBlack);
      doc.text(`+254 ${this.pickedReceiptClientPhoneNumber}`, 57, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };
    doc.text(`Prepared By `, 130, currentHeight, { align: 'left' },);
    doc.text(`:`, 160, currentHeight, { align: 'left' },);
    if (this.pickedReceiptPreparedBy) {
      doc.setTextColor(colorBlack);
      doc.text(` ${this.pickedReceiptPreparedBy}`, 162, currentHeight, { align: 'left' },);
      doc.setTextColor(colorGray);
    };

    // If 0 > X < 26
    if ((this.pickedReceiptReceiptItems.length > 0) && (this.pickedReceiptReceiptItems.length < 26)) {
      console.log(`0 > ${this.pickedReceiptReceiptItems.length} < 26`);
      // addPageOne(true);

      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 135, 0, 0);

        for (let index = 0; index < 25; index++) {

          if (this.pickedReceiptReceiptItems[index]) {

            doc.text(`${(index + 1)}`, 27, currentHeight, { align: 'left' },);
            if (this.pickedReceiptReceiptItems[index].description) {
              doc.text(`${this.pickedReceiptReceiptItems[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.pickedReceiptReceiptItems[index].quantity) {
              doc.text(`${this.pickedReceiptReceiptItems[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.pickedReceiptReceiptItems[index].rate) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.pickedReceiptReceiptItems[index].itemTotal) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }


        if (this.pickedReceiptReceiptTotalCost) {
          doc.setTextColor(colorBlack);
          doc.text(`Total`, 70, currentHeight, { align: 'right' },);
          // if (this.receiptTotalQuantity) {
          //   doc.text(`${this.receiptTotalQuantity}`, 126, currentHeight, { align: 'center' },);
          // };
          doc.setFontSize(14);
          doc.text(`${formatCurrency((this.pickedReceiptReceiptTotalCost), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
          doc.setFontSize(12);
        };

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.pickedReceiptDate) {
          doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp
      }

    };
    // If 26 < X < 61
    if ((this.pickedReceiptReceiptItems.length > 26) && (this.pickedReceiptReceiptItems.length < 61)) {
      console.log(`25 > ${this.pickedReceiptReceiptItems.length} < 56`);
      // addPageOne(false);
      // addPageOtherThanOne(25, 56, true);

      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 135, 0, 0);

        for (let index = 0; index < 25; index++) {

          if (this.pickedReceiptReceiptItems[index]) {
            doc.text(`${(index + 1)}`, 27, currentHeight, { align: 'left' },);
            if (this.pickedReceiptReceiptItems[index].description) {
              doc.text(`${this.pickedReceiptReceiptItems[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.pickedReceiptReceiptItems[index].quantity) {
              doc.text(`${this.pickedReceiptReceiptItems[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.pickedReceiptReceiptItems[index].rate) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.pickedReceiptReceiptItems[index].itemTotal) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.pickedReceiptDate) {
          doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }

      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.subLineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 4, 160, 180, 0, 0);

        for (let index = 26; index < 61; index++) {

          if (this.pickedReceiptReceiptItems[index]) {
            doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
            if (this.pickedReceiptReceiptItems[index].description) {
              doc.text(`${this.pickedReceiptReceiptItems[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.pickedReceiptReceiptItems[index].quantity) {
              doc.text(`${this.pickedReceiptReceiptItems[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.pickedReceiptReceiptItems[index].rate) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.pickedReceiptReceiptItems[index].itemTotal) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          };

        };

        if (this.pickedReceiptReceiptTotalCost) {
          doc.setTextColor(colorBlack);
          doc.text(`Total`, 70, currentHeight, { align: 'right' },);
          // if (this.receiptTotalQuantity) {
          //   doc.text(`${this.receiptTotalQuantity}`, 126, currentHeight, { align: 'center' },);
          // };
          doc.setFontSize(14);
          doc.text(`${formatCurrency((this.pickedReceiptReceiptTotalCost), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
          doc.setFontSize(12);
        };

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.pickedReceiptDate) {
          doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp
      }

    };
    // If 61 < X < 96
    if ((this.pickedReceiptReceiptItems.length > 61) && (this.pickedReceiptReceiptItems.length < 96)) {
      console.log(`55 > ${this.pickedReceiptReceiptItems.length} < 86`);
      // addPageOne(false);
      // addPageOtherThanOne(25, 56, false);
      // addPageOtherThanOne(55, 86, true);


      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 135, 0, 0);

        for (let index = 0; index < 25; index++) {
          if (this.pickedReceiptReceiptItems[index]) {
            doc.text(`${(index + 1)}`, 27, currentHeight, { align: 'left' },);
            if (this.pickedReceiptReceiptItems[index].description) {
              doc.text(`${this.pickedReceiptReceiptItems[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.pickedReceiptReceiptItems[index].quantity) {
              doc.text(`${this.pickedReceiptReceiptItems[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.pickedReceiptReceiptItems[index].rate) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.pickedReceiptReceiptItems[index].itemTotal) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.pickedReceiptDate) {
          doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }

      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.subLineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 4, 160, 180, 0, 0);

        for (let index = 26; index < 61; index++) {
          if (this.pickedReceiptReceiptItems[index]) {
            doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
            if (this.pickedReceiptReceiptItems[index].description) {
              doc.text(`${this.pickedReceiptReceiptItems[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.pickedReceiptReceiptItems[index].quantity) {
              doc.text(`${this.pickedReceiptReceiptItems[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.pickedReceiptReceiptItems[index].rate) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.pickedReceiptReceiptItems[index].itemTotal) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.pickedReceiptDate) {
          doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }
      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 180, 0, 0);
        currentHeight += (-4);
        for (let index = 61; index < 96; index++) {

          if (this.pickedReceiptReceiptItems[index]) {
            if (this.pickedReceiptReceiptItems[index]) {
              doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
              if (this.pickedReceiptReceiptItems[index].description) {
                doc.text(`${this.pickedReceiptReceiptItems[index].description}`, 35, currentHeight, { align: 'left' },);
              };
              if (this.pickedReceiptReceiptItems[index].quantity) {
                doc.text(`${this.pickedReceiptReceiptItems[index].quantity}`, 126, currentHeight, { align: 'center' },);
              };
              if (this.pickedReceiptReceiptItems[index].rate) {
                doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
              };
              if (this.pickedReceiptReceiptItems[index].itemTotal) {
                doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
              };
              currentHeight += pdfConfig.subLineHeight;
            }
          }

        }

        if (true) {
          if (this.pickedReceiptReceiptTotalCost) {
            doc.setTextColor(colorBlack);
            doc.text(`Total`, 70, currentHeight, { align: 'right' },);
            // if (this.receiptTotalQuantity) {
            //   doc.text(`${this.receiptTotalQuantity}`, 126, currentHeight, { align: 'center' },);
            // };
            doc.setFontSize(14);
            doc.text(`${formatCurrency((this.pickedReceiptReceiptTotalCost), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            doc.setFontSize(12);
          };
          currentHeight += pdfConfig.subLineHeight;
        }

        //// Receipt Details - End

        // Stamp
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.pickedReceiptDate) {
          doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp
      }

    };
    // If 96 < X < 131
    if ((this.pickedReceiptReceiptItems.length > 96) && (this.pickedReceiptReceiptItems.length < 131)) {
      console.log(`85 > ${this.pickedReceiptReceiptItems.length} < 116`);
      // addPageOne(false);
      // addPageOtherThanOne(25, 56, false);
      // addPageOtherThanOne(55, 86, false);
      // addPageOtherThanOne(85, 116, true);

      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 135, 0, 0);

        for (let index = 0; index < 25; index++) {

          if (this.pickedReceiptReceiptItems[index]) {
            doc.text(`${(index + 1)}`, 27, currentHeight, { align: 'left' },);
            if (this.pickedReceiptReceiptItems[index].description) {
              doc.text(`${this.pickedReceiptReceiptItems[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.pickedReceiptReceiptItems[index].quantity) {
              doc.text(`${this.pickedReceiptReceiptItems[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.pickedReceiptReceiptItems[index].rate) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.pickedReceiptReceiptItems[index].itemTotal) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }


        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.pickedReceiptDate) {
          doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }
      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.subLineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 4, 160, 180, 0, 0);

        for (let index = 26; index < 61; index++) {
          if (this.pickedReceiptReceiptItems[index]) {

            doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
            if (this.pickedReceiptReceiptItems[index].description) {
              doc.text(`${this.pickedReceiptReceiptItems[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.pickedReceiptReceiptItems[index].quantity) {
              doc.text(`${this.pickedReceiptReceiptItems[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.pickedReceiptReceiptItems[index].rate) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.pickedReceiptReceiptItems[index].itemTotal) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        //// Receipt Details - End

        // Stamp
        currentHeight += pdfConfig.subLineHeight;
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.pickedReceiptDate) {
          doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }
      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.lineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 8, 160, 180, 0, 0);
        currentHeight += (-4);
        for (let index = 61; index < 96; index++) {

          if (this.pickedReceiptReceiptItems[index]) {

            doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
            if (this.pickedReceiptReceiptItems[index].description) {
              doc.text(`${this.pickedReceiptReceiptItems[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.pickedReceiptReceiptItems[index].quantity) {
              doc.text(`${this.pickedReceiptReceiptItems[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.pickedReceiptReceiptItems[index].rate) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.pickedReceiptReceiptItems[index].itemTotal) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        //// Receipt Details - End

        // Stamp
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.pickedReceiptDate) {
          doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp

        doc.addPage();
        addHeader();
      }
      {
        // Receipt Details - Start
        doc.setFontSize(11);
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight + 3, 160, 12, 0, 0);

        currentHeight += pdfConfig.lineHeight;

        doc.text(`No.`, (27), currentHeight, { align: 'left' },);
        doc.text(`Description`, (35), currentHeight, { align: 'left' },);
        doc.text(`Quantity`, (126), currentHeight, { align: 'center' },);
        doc.text(`Rate`, (156), currentHeight, { align: 'right' },);
        doc.text(`Total`, (184), currentHeight, { align: 'right' },);
        currentHeight += pdfConfig.subLineHeight;

        doc.text(`Count`, (125), currentHeight, { align: 'center' },);
        currentHeight += pdfConfig.subLineHeight;

        // Page 1
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(25, currentHeight - 4, 160, 180, 0, 0);
        for (let index = 96; index < 131; index++) {

          if (this.pickedReceiptReceiptItems[index]) {

            doc.text(`${(index)}`, 27, currentHeight, { align: 'left' },);
            if (this.pickedReceiptReceiptItems[index].description) {
              doc.text(`${this.pickedReceiptReceiptItems[index].description}`, 35, currentHeight, { align: 'left' },);
            };
            if (this.pickedReceiptReceiptItems[index].quantity) {
              doc.text(`${this.pickedReceiptReceiptItems[index].quantity}`, 126, currentHeight, { align: 'center' },);
            };
            if (this.pickedReceiptReceiptItems[index].rate) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].rate), 'en-US', '', '', '1.2-2')}`, 156, currentHeight, { align: 'right' },);
            };
            if (this.pickedReceiptReceiptItems[index].itemTotal) {
              doc.text(`${formatCurrency((this.pickedReceiptReceiptItems[index].itemTotal), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
            };
            currentHeight += pdfConfig.subLineHeight;
          }

        }

        if (this.pickedReceiptReceiptTotalCost) {
          doc.setTextColor(colorBlack);
          doc.text(`Total`, 70, currentHeight, { align: 'right' },);
          // if (this.receiptTotalQuantity) {
          //   doc.text(`${this.receiptTotalQuantity}`, 126, currentHeight, { align: 'center' },);
          // };
          doc.setFontSize(14);
          doc.text(`${formatCurrency((this.pickedReceiptReceiptTotalCost), 'en-US', '', '', '1.2-2')}`, 184, currentHeight, { align: 'right' },);
          doc.setFontSize(12);
        };
        currentHeight += pdfConfig.subLineHeight;


        //// Receipt Details - End

        // Stamp
        currentHeight = 242;
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(90, currentHeight, 95, 25, 1, 1);
        doc.setDrawColor(0);

        currentHeight += pdfConfig.lineHeight;
        doc.text(`Signature :`, 120, currentHeight, { align: 'right' },);
        doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        currentHeight += pdfConfig.lineHeight;
        doc.text(`Date :`, 120, currentHeight, { align: 'right' },);

        if (this.pickedReceiptDate) {
          doc.text(` ${formatDate(this.pickedReceiptDate, 'dd/MM/yy', 'en-US')}`, 120, currentHeight, { align: 'left' },);
        } else {
          doc.text(`................................................`, 120, currentHeight, { align: 'left' },);
        }

        // stamp_750_x287
        var imageHeader = new Image();
        imageHeader.src = "../../../../assets/images/logo/stamp_750_x287.png";
        doc.addImage(
          imageHeader,
          "JPEG",
          122,
          currentHeight - 21,
          75,
          28.7
        );
        // Stamp
      }
    };


    //#region Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(pdfConfig.headerFieldTextSize);
        doc.setTextColor(colorWhite);

        if (param.pageEnable) {
          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            0,
            doc.internal.pageSize.height - 29,
            210,
            30
          );

          doc.text("Park Medical Center (PMC), 3rd Parklands Avenue, 2nd Floor, Nairobi.", 15, docHeight - 12, { align: 'left' });
          doc.text("+254 718 862 006", 160, docHeight - 12, { align: 'left' });
          doc.text("www.vistaservices.co.ke", 15, docHeight - 3, { align: 'left' });
          doc.text("www.facebook.com/VistaServicesKenya", 116, docHeight - 3, { align: 'left' });
          doc.setTextColor(colorBlack);
          doc.text(
            param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
            docWidth - 5,
            doc.internal.pageSize.height - 30,
            { align: 'right' }
          );
          doc.setTextColor(colorWhite);

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_globe_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            8,
            doc.internal.pageSize.height - 7,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_location_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            8,
            doc.internal.pageSize.height - 16,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_phone_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            154,
            doc.internal.pageSize.height - 16,
            5,
            5
          );

          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_facebook_v2.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            110,
            doc.internal.pageSize.height - 7,
            5,
            5
          );
        }

      }
    }

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        0,
        doc.internal.pageSize.height - 29,
        210,
        30
      );

      doc.setFontSize(pdfConfig.fieldTextSize);
      doc.setTextColor(colorWhite);
      doc.text("Park Medical Center (PMC), 3rd Parklands Avenue, 2nd Floor, Nairobi.", 15, docHeight - 12, { align: 'left' });
      doc.text("+254 718 862 006", 160, docHeight - 12, { align: 'left' });
      doc.text("www.vistaservices.co.ke", 15, docHeight - 3, { align: 'left' });
      doc.text("www.facebook.com/VistaServicesKenya", 116, docHeight - 3, { align: 'left' });
      doc.setTextColor(colorBlack);
      doc.text(
        param.pageLabel + " 1 / 1",
        docWidth - 5,
        doc.internal.pageSize.height - 30,
        { align: 'right' }
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_globe_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        8,
        doc.internal.pageSize.height - 7,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_location_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        8,
        doc.internal.pageSize.height - 16,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_phone_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        154,
        doc.internal.pageSize.height - 16,
        5,
        5
      );

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_16_x_16_facebook_v2.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        110,
        doc.internal.pageSize.height - 7,
        5,
        5
      );

      doc.setTextColor(colorWhite);
    }
    //#endregion


    let returnObj = {
      pagesNumber: doc.getNumberOfPages(),
    };



    if (param.outputType === "save") {
      // this.disableDownloadButton = false;
      doc.save(param.fileName);
    }

    return returnObj;
  };


  deleteReceipt(id: string) {

    let receiptName = "";

    this.subscription.add(
      this.vistaService.deleteItem("receipt",id)
        .pipe(first()).subscribe((result) => {

          //Get User Data
          this.show_alert = true;
          this.alert_message = `${receiptName} Deleted!`;
          this.alert_type = "success";

          this.toastr.success(`${receiptName} Deleted!`, "Info!");

          setTimeout(() => {
            this.show_alert = false;
            this.selfClosingAlert.close();
          }, 3000);

        },
          (error: string) => {
            this.toastr.error(`${receiptName} was not Deleted, error: ${error}!`, "Info!");
            this.error = error;
            this.loading = false;
          }));
  }

  // table action cell formatter
  userActionFormatter(a: ReceiptItem): SafeHtml {
      if (a.receiptId) {
        return this.sanitizer.bypassSecurityTrustHtml(
          `<button id="${a.receiptId}" class="btn btn-sm btn-outline-secondary mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 view-edit" type="button" *ngIf="!updateInProgress"> <i class=""></i>Download PDF</button>
            <button id="${a.receiptId}" class="btn btn-sm btn-outline-danger mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 delete" type="button" *ngIf="!updateInProgress"> <i class=""></i>X</button>`
        )
      }

    // <button id="${a.receiptId}" class="btn btn-sm btn-outline-secondary mb-1 mb-sm-1 mb-md-1 mb-lg-1 mb-xl-1 postInvestment" type="button" *ngIf="!updateInProgress"> <i class=""></i>Post Investment</button>
  }

  numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  // compares two cell valueuser?: ReceiptItem;
  user_complete?: boolean;
  compare(v1: string | number | boolean, v2: string | number | boolean): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  /**
   * Sort the table data
   * @param event column name,sort direction
   */
  sort(event: SortEvent): void {
    if (event.direction === '') {
      this.receiptArray = this.defaultReceiptArray;
      //this.getReceiptList();
    } else {
      this.receiptArray = [...this.receiptArray].sort((a, b) => {
        const res = this.compare(a["0"][event.column], b["0"][event.column]);
        return event.direction === 'asc' ? res : -res;
      });
    }
  }

  /**
 * Table Data Match with Search input
 * @param tableRow Table row
 * @param term Search the value
 */
  matches(tableRow: ReceiptItem, term: string) {
    return tableRow.receiptId?.toLowerCase().includes(term)
      || tableRow.clientFirstName?.toLowerCase().includes(term)
      || tableRow.clientOtherName?.toLowerCase().includes(term)
      || tableRow.clientSurname?.toLowerCase().includes(term)
      || tableRow.clientEmail?.toLowerCase().includes(term);
  };

  /**
   * Search Method
  */
  searchData(searchTerm: string): void {
    if (searchTerm === '') {
      // this.getReceiptList();
      this.receiptArray = this.defaultReceiptArray;
    }
    else {
      let updatedData = this.receiptArray = this.defaultReceiptArray;
      //  filter
      updatedData = updatedData.filter(a => this.matches(a, searchTerm));
      this.receiptArray = updatedData;
    }
  }

  hasChanged($event: any) {
    // Clear all other address details?
  }

  createRecord() {
    this.router.navigate([`portal/receipt/create`]);
  }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}


